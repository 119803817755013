import Axios from "core/axios";

class OpportunitiesServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CRM;
    this.service = new Axios(this.api_url);
  }

  getOpportunities = (franchise) => {
    return this.service.get("/opportunities?franchise=" + franchise);
  };

  updateOpportunity = (data) => {
    return this.service.put("/opportunities/" + data._id, data);
  };

  deleteOpportunity = (id) => {
    return this.service.delete("/opportunities/" + id);
  };

  getOpportunity = (id, franchise) => {
    // console.log('¡getopp service', id, franchise)
    return this.service.get("/opportunities/" + id + "?franchise=" + franchise);
  };
}

export default OpportunitiesServices;
