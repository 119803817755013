import React, { Component } from "react";
import UserContext from "providers/UserProvider";
import {
  AcademyCoursesSingle,
  Grid,
  convertToRaw,
  EditorState,
  convertFromRaw,
  draftToHtml,
  ContentState,
  htmlToDraft,
} from "react-library-sm";
import { Redirect } from "react-router-dom";
import AcademyServices from "services/central-academy/academy.services";

class AcademyCoursesSingleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: null,
      alertAPI: false,
      alert: null,
      languages: null,
      status: "draft",
      category: "",
      tags: [],
    };
    this.academyService = new AcademyServices();
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  componentDidMount() {
    // load course
    let languages = {
      es: {
        name: "Curso español",
        description: "Descripción del curso",
        editorState: null,
        editorStateSaved: null,
        html: null,
        htmlSaved: null,
        edited: false,
        saved: true,
        reviewed: false,
        status: "draft",
      },
    };

    this.setState({ languages });
    this.academyService
      .getCourse(this.props.match.params.id)
      .then((response) => {
        // console.log("response language", response.data);
        let languages = {};
        response.data[0].keys.forEach((key) => {
          languages[key.code] = { status: key.status, downloaded: false };
        });
        let language = response.data[0].languages;
        languages[language.code] = {
          ...languages[language.code],
          downloaded: true,
          name: language.name,
          description: language.description,
          reviewed: language.reviewed,
          status: language.status,
          html: language.blocks ? language.blocks.html : null,
          htmlSaved: language.blocks ? language.blocks.html : null,
          edited: false,
          editorState: language.blocks
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(language.blocks.html).contentBlocks,
                  htmlToDraft(language.blocks.html).entityMap
                )
              )
            : EditorState.createEmpty(),
          editorStateSaved: language.blocks
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(language.blocks.html).contentBlocks,
                  htmlToDraft(language.blocks.html).entityMap
                )
              )
            : EditorState.createEmpty(),
        };
        let course = { ...response.data[0], languages: languages };
        // console.log("final course componentDidMount", course);
        this.setState({
          category: response.data[0].category,
          tags: response.data[0].tags,
          status: response.data[0].status,
          languages: languages,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  handleEditorChange = (content, html, language) => {
    // console.log("language handle", language);
    const languages = { ...this.state.languages };
    if (languages[language].htmlSaved === html) {
      languages[language].edited = false;
      languages[language].saved = true;
    } else {
      languages[language].edited = true;
      languages[language].saved = false;
    }
    languages[language] = {
      ...languages[language],
      editorState: content,
      html: html,
    };
    this.setState({ languages });
  };

  handleSaveLanguage = (language) => {
    const languages = { ...this.state.languages };

    this.academyService
      .saveLanguageContent(this.props.match.params.id, language, {
        html: draftToHtml(
          convertToRaw(languages[language].editorState.getCurrentContent())
        ),
      })
      .then((response) => {
        languages[language] = {
          ...languages[language],
          editorStateSaved: languages[language].editorState,
          htmlSaved: languages[language].html,
          edited: false,
          saved: true,
          reviewed: false,
        };

        this.setState({ languages });
      });
  };

  handleReviewLanguage = (language) => {
    const languages = { ...this.state.languages };
    this.academyService
      .reviewLanguage(this.props.match.params.id, language, true)
      .then((response) => {
        languages[language] = {
          ...languages[language],
          reviewed: true,
        };

        this.setState({ languages });
      });
  };

  handleEditLanguage = (values, language) => {
    const languages = { ...this.state.languages };
    this.academyService
      .editLanguageInfo(this.props.match.params.id, language, {
        name: values.name,
        description: values.description,
      })
      .then((response) => {
        languages[language] = {
          ...languages[language],
          name: values.name,
          description: values.description,
        };

        this.setState({ languages });
      });
  };

  handleDeleteLanguage = (language) => {
    const languages = { ...this.state.languages };

    this.academyService
      .deleteLanguage(this.props.match.params.id, language)
      .then((response) => {
        delete languages[language];

        this.setState({ languages });
      });
  };

  handleAddLanguage = (values) => {
    const languages = { ...this.state.languages };
    // console.log("values", values);
    Promise.all(
      Object.keys(values).map((value) => {
        // console.log("value in central", values[value].editorState);
        let language = {
          code: value,
          name: values[value].name,
          description: values[value].description,
          blocks: { html: draftToHtml(values[value].editorState) },
          reviewed: false,
          status: "draft",
        };
        return this.academyService
          .addLanguage(this.props.match.params.id, language)
          .then((response) => {
            languages[value] = {
              ...values[value],
              editorState: EditorState.createWithContent(
                convertFromRaw(values[value].editorState)
              ),
              downloaded: true,
            };
          });
      })
    ).then((response) => {
      this.setState({ languages });
    });
  };

  handleRestartCourse = () => {
    const esLanguage = this.state.languages.es;
    this.academyService
      .restartCourse(this.props.match.params.id)
      .then((response) => {
        this.setState({ languages: { es: esLanguage }, status: "building" });
      });
  };

  handleChangeStatusCourse = (status) => {
    // console.log("handle restart course");
    this.academyService
      .editCourseStatus(this.props.match.params.id, status)
      .then((response) => {
        // console.log("status", status);

        this.setState({ status });
      });
  };

  handleDeleteCourse = () => {
    this.academyService
      .deleteCourse(this.props.match.params.id)
      .then((response) => {
        this.setState({ deleted: true });
      });
  };

  downloadLanguage = (language) => {
    //descargamos language
    this.academyService
      .getCourseLanguage(this.props.match.params.id, language)
      .then((response) => {
        // console.log("response language", response.data);
        const languages = { ...this.state.languages };
        let languageDownload = response.data[0].languages;
        languages[language] = {
          ...languages[languageDownload],
          downloaded: true,
          name: languageDownload.name,
          description: languageDownload.description,
          reviewed: languageDownload.reviewed,
          status: languageDownload.status,
          html: languageDownload.blocks ? languageDownload.blocks.html : null,
          htmlSaved: languageDownload.blocks
            ? languageDownload.blocks.html
            : null,
          edited: false,
          editorState: languageDownload.blocks
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(languageDownload.blocks.html).contentBlocks,
                  htmlToDraft(languageDownload.blocks.html).entityMap
                )
              )
            : EditorState.createEmpty(),
          editorStateSaved: languageDownload.blocks
            ? EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(languageDownload.blocks.html).contentBlocks,
                  htmlToDraft(languageDownload.blocks.html).entityMap
                )
              )
            : EditorState.createEmpty(),
        };
        let course = { ...response.data[0], languages: languages };
        // console.log("final course", course);
        this.setState({
          status: response.data[0].status,
          languages: languages,
        });
      });
  };

  handleSubmitEditTags = (values) => {
    // console.log("values tags", values.tags);
    this.academyService
      .editTags(this.props.match.params.id, { tags: values.tags })
      .then((response) => {
        this.setState({ tags: values.tags });
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  handleSubmitEditCategory = (values) => {
    // console.log("values category", values.category);
    this.academyService
      .editCategory(this.props.match.params.id, { category: values.category })
      .then((response) => {
        this.setState({ category: values.category });
      })
      .catch((err) => {
        // console.log("err", err);
      });
  };

  handleChangeStatusLanguage = (language, status) => {
    this.academyService
      .editLangaugeStatus(this.props.match.params.id, language, {
        status: status,
      })
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language].status = status;
        this.setState({ languages });
      })
      .catch((err) => {
        // console.log("err change status language", err.response);
      });
  };

  render() {
    // console.log(this.props.match.params.id);
    // let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {this.state.deleted && <Redirect to="/academy/courses" />}

        {alert}
        <AcademyCoursesSingle
          editorState={this.state.editorState}
          handleEditorChange={this.handleEditorChange}
          languages={this.state.languages}
          handleRestartCourse={this.handleRestartCourse}
          handleChangeStatusCourse={this.handleChangeStatusCourse}
          handleDeleteCourse={this.handleDeleteCourse}
          handleSaveLanguage={this.handleSaveLanguage}
          handleReviewLanguage={this.handleReviewLanguage}
          handleEditLanguage={this.handleEditLanguage}
          handleDeleteLanguage={this.handleDeleteLanguage}
          handleAddLanguage={this.handleAddLanguage}
          downloadLanguage={this.downloadLanguage}
          tags={this.state.tags}
          category={this.state.category}
          handleSubmitEditTags={this.handleSubmitEditTags}
          handleSubmitEditCategory={this.handleSubmitEditCategory}
          handleChangeStatusLanguage={this.handleChangeStatusLanguage}
          status={this.state.status}
          server={process.env.REACT_APP_API_CENTRAL_ACADEMY}
        />
      </Grid>
    );
  }
}

export default AcademyCoursesSingleComponent;
