import React from "react";

export const userContext = React.createContext({
  language: null,
  translation: null,
  userCognito: null,
  menuOpen: false
});

export default userContext;
