// import moment from "moment";

// let beginPreviousMonth = moment().subtract(1, "M").format("YYYY-MM-01");
// let endPreviousMonth =
//   moment().subtract(1, "M").format("YYYY-MM-") + moment().daysInMonth();

// let begintCurrentMonth = moment().subtract(1, "M").format("YYYY-MM-01");
// let endCurrentMonth =
//   moment().subtract(1, "M").format("YYYY-MM-") + moment().daysInMonth();

export const createDashboard = (invoices) => {
  // console.log(
  //   "invoices to check dashboard",
  //   invoices.filter((invoice) => invoice.category === "others")
  // );
  // ["others", "recurring_fee", "initial_fee", "office", "marketing"];
  return {
    recurring_fee: invoices
      .filter((invoice) => invoice.category === "recurring_fee")
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    initial_fee: invoices
      .filter((invoice) => invoice.category === "initial_fee")
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    other: invoices
      .filter(
        (invoice) =>
          invoice.category === "others" ||
          invoice.category === "office" ||
          invoice.category === "marketing" ||
          invoice.category === "marketplace"
      )
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    // office: invoices
    //   .filter((invoice) => invoice.category === "office")
    //   .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    // marketing: invoices
    //   .filter((invoice) => invoice.category === "marketing")
    //   .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    // marketplace: invoices
    // .filter((invoice) => invoice.category === "marketplace")
    // .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    paid: invoices
      .filter((invoice) => invoice.status === "paid")
      .reduce((total, currentInvoice) => currentInvoice.total + total, 0),
    pending: invoices
      .filter(
        (invoice) =>
          invoice.status === "pending" ||
          invoice.status === "open" ||
          invoice.status === "uploaded_to_face"
      )
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    open: invoices
      .filter((invoice) => invoice.status === "open")
      .reduce((total, currentInvoice) => currentInvoice.subtotal + total, 0),
    total: invoices.reduce(
      (total, currentInvoice) => currentInvoice.subtotal + total,
      0
    ),
  };
};
