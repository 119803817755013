import React, { Component } from "react";
import TemplatesServices from "services/templates.services";

import { CentralTemplate as Template, Grid } from "react-library-sm";

class TemplateComponent extends Component {
  constructor(props) {
    super(props);
    this.service = new TemplatesServices();
    this.state = { template: {} };
    this.child = React.createRef();
  }

  componentDidMount() {
    switch (this.props.variant) {
      case "view":
      case "edit":
        this.service
          .getTemplate(this.props.match.params.id, "es")
          .then((response) => {
            console.log("response template", response);
            const template = { ...response.data[0] };
            template.keys
              .filter((key) => key.length === 2)
              .forEach((key) => {
                this.service
                  .getTemplate(this.props.match.params.id, key)
                  .then((response) => {
                    template[key] = response.data[0][key];
                    this.setState({ template }, () => {
                      this.child.current.setTemplateStateFromParent(template);
                    });
                  });
              });
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      case "add":
        let template = {
          type: "event",
          es: { name: "Cambiar nombre", phases: [], order: 0 },
        };
        this.service
          .postTemplate(template)
          .then((response) => {
            // console.log(response);
            this.setState({ template: response.data }, () => {
              this.child.current.setTemplateStateFromParent(response.data);
            });
          })
          .catch((error) => {
            console.log(error);
          });

        break;
      default:
        break;
    }
  }

  saveTemplateHandler = (template) => {
    if (this.props.variant === "add") {
      // console.log("template to push", template);
      this.service
        .putTemplateLanguages(template)
        .then((response) => {
          // console.log("response", response);
          if (response.status === 200) {
            this.setState({ template: response.data }, () => {
              this.child.current.setTemplateStateFromParent(response.data);
            });
          } else {
            alert("Codigo recibido no implementado");
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      this.service
        .putTemplate(template)
        .then((response) => {
          if (response.status === 200) {
            this.setState({ template: template }, () => {
              this.child.current.setTemplateStateFromParent(template);
            });
          } else {
            alert("Codigo recibido no implementado");
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Template
          template={this.state.template}
          variant={this.props.variant}
          saveHandler={this.saveTemplateHandler}
          linkCancel="/templates"
          ref={this.child}
        />
      </Grid>
    );
  }
}

export default TemplateComponent;
