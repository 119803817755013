import React, { Component } from "react";

import OfficeServices from "services/central-billing/office.services";
import UserContext from "providers/UserProvider";
import { Office, Grid, setLocale } from "react-library-sm";

class OfficeListComponent extends Component {
  constructor(props) {
    super(props);
    this.officeService = new OfficeServices();

    this.state = {
      officeSubscriptions: [],
      dashboard: {},
      franchises: [],
      plans: [],
      products: [],
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    setLocale("ES");
    this.officeService.getOfficeSubscriptions().then((response) => {
      this.setState({
        officeSubscriptions: response.data.franchises,
        dashboard: response.data.dashboard,
      });
    });

    this.officeService.getSubscriptionInfo().then((response) => {
      // console.log("getSubscriptionInfo response", response);
      this.setState({
        ...this.state,
        franchises: response.data.franchises,
        plans: response.data.plans,
        products: response.data.products,
      });
    });
  }

  createOfficeSubscription = (values) => {
    // console.log("createSubscription values", JSON.stringify(values));

    this.officeService
      .postOfficeSubscription(values)
      .then((response) => {
        // console.log("postOfficeSubscription response", response);
        if (response.status === 200) {
          let officeSubscriptions = [...this.state.officeSubscriptions];
          if (
            officeSubscriptions.find(
              (franchise) => franchise._id === response.data._id
            )
          ) {
            officeSubscriptions.some((franchise, index) => {
              if (franchise._id === response.data._id) {
                officeSubscriptions[index] = response.data;
                return true;
              } else return false;
            });
          } else officeSubscriptions.push(response.data);
          this.setState({ officeSubscriptions });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Office
          createOfficeSubscription={this.createOfficeSubscription}
          data={this.state.officeSubscriptions}
          dashboard={this.state.dashboard}
          plans={this.state.plans}
          products={this.state.products}
          clients={this.state.franchises}
          columns={this.context.translation.dataTables.office.columns}
          title={this.context.translation.dataTables.office.title}
        />
      </Grid>
    );
  }
}

export default OfficeListComponent;
