import Axios from "core/axios";

class InvoiceServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_BILLING;
    this.service = new Axios(this.api_url);
  }

  getInvoices = (categories, statuses, dates) => {
    return this.service.get(
      `/invoices?centralId=5df776376aa15623df72a6b7${
        categories && categories.length > 0
          ? `&categories=${JSON.stringify(categories)}`
          : ""
      }${
        statuses && statuses.length > 0
          ? `&statuses=${JSON.stringify(statuses)}`
          : ""
      }${
        dates && dates.from && dates.from !== "Invalid Date"
          ? `&from=${dates.from}`
          : ""
      }${
        dates && dates.to && dates.to !== "Invalid Date"
          ? `&to=${dates.to}`
          : ""
      }`
    );
  };

  getInvoiceInfo = () =>
    this.service.get("/invoices/info?centralId=5df776376aa15623df72a6b7");
  getInvoicePdf = id => this.service.get(`/invoices/${id}`);
  getInvoice = id => this.service.get(`/invoices/${id}`);
  postInvoice = data =>
    this.service.post("/invoices?centralId=5df776376aa15623df72a6b7", data);
  putInvoice = data => this.service.put(`/invoices/${data._id}`, data);
  deleteInvoice = (id, franchise) =>
    this.service.delete(`/invoices/${id}?franchise=${franchise}`);
  updateStatus = data =>
    this.service.put(
      `/invoices/${data.id}/status?centralId=5df776376aa15623df72a6b7`,
      data
    );
  duplicateInvoice = id =>
    this.service.post(
      `/invoices/${id}/duplicate?centralId=5df776376aa15623df72a6b7`
    );
  amendInvoice = id =>
    this.service.put(
      `/invoices/${id}/amend?centralId=5df776376aa15623df72a6b7`
    );
  sendNotification = ids =>
    this.service.put(
      `/invoices/notification?centralId=5df776376aa15623df72a6b7`,
      ids
    );
}

export default InvoiceServices;
