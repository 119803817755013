import React, { Component } from "react";
import FranchiseServices from "services/franchises.services.js";
import InvoiceServices from "services/central-billing/invoices.services";
import UserServices from "services/users.services.js";
import UserContext from "providers/UserProvider";
import moment from "moment";
import {
  CentralFranchises as Franchises,
  Grid,
  setLocale,
} from "react-library-sm";
import { createDashboardFranchises } from "utils/franchises";

import _ from "lodash";

class FranchisesComponent extends Component {
  constructor(props) {
    super(props);
    this.franchiseService = new FranchiseServices();
    this.invoiceService = new InvoiceServices();
    this.userService = new UserServices();
    this.state = {
      franchises: [],
      history: [],
      taxes: [],
      dashboard: {},
      compareDashboard: {},
      yearsDownloaded: [`${moment().format("YYYY")}`],
      compareYears: [],
      year: moment().format("YYYY"),
    };
  }

  componentDidMount() {
    setLocale("ES");
    this.franchiseService
      .getFranchisesDashboardByYear(this.state.year)
      .then((response) => {
        const responseParsed = createDashboardFranchises(
          response.data,
          this.state.year
        );

        this.setState({
          franchises: responseParsed.franchises,
          history: responseParsed.history,
          taxes: responseParsed.taxes,
          dashboard: { ...responseParsed.dashboard },
          compareDashboard: { ...responseParsed.dashboard },
        });
      })
      .catch((err) => {
        // console.log("err get franchises", err);
      });
  }

  static contextType = UserContext;

  createFranchise = (data) => {
    // console.log(JSON.stringify(data));
    const { users } = data;
    // console.log(data);
    const newFranchise = {
      ...data,
      users: [],
    };

    this.franchiseService
      .postFranchise({ ...newFranchise, stripe: users[0] })
      .then((response) => {
        const tempFranchise = { ...response.data };
        const franchises = [...this.state.franchises];
        const usersAdded = Promise.all(
          users.map(({ name, email }) => {
            return this.userService
              .postUser({
                name,
                email,
                franchises: [{ id: tempFranchise._id, rol: "franchise" }],
              })
              .then((responseUser) => {
                // console.log("responseUser", responseUser);
                tempFranchise.users.push(responseUser.data._id);
              });
          })
        );

        // console.log("usersAdded");
        // console.log(usersAdded);
        usersAdded
          .then((x) => {
            return this.franchiseService.putFranchise(tempFranchise);
          })
          .then((franch) => {
            // console.log(franch);
            franchises.push(tempFranchise);
            this.setState({ franchises: franchises });
            // console.log();
          });
      })
      .catch((err) => console.log(err));
  };

  editFranchise = (data) => {
    // console.log("data en editfranchise", data);
    this.franchiseService.putFranchise(data).then((response) => {
      let franchises = [...this.state.franchises];
      franchises.some((franchise, index) => {
        if (franchise._id === response.data._id) {
          franchises[index] = response.data;
          return true;
        } else return false;
      });
      this.setState({ franchises });
    });
  };

  handleChangeYear = (year) => {
    this.setState({ year }, () => {
      this.franchiseService
        .getFranchisesDashboardByYear(year)
        .then((response) => {
          console.log("getFranchises", response, year);
          const responseParsed = createDashboardFranchises(response.data, year);
          console.log("parsed dashboard", responseParsed.dashboard);
          this.setState({
            franchises: responseParsed.franchises,
            history: responseParsed.history,
            taxes: responseParsed.taxes,
            dashboard: { ...responseParsed.dashboard },
          });
        })
        .catch((err) => {
          console.log("err get franchises", err);
        });
    });
  };

  handleSelectYears = (years) => {
    console.log("years to download", years);
    const stateYears = [...this.state.yearsDownloaded];
    const yearsToDownload = years.filter((year) => !stateYears.includes(year));

    yearsToDownload.forEach((year) => {
      this.handleDownloadCompareYear(year);
      stateYears.push(year);
    });

    this.setState({ yearsDownloaded: stateYears, compareYears: years });
    // const downloadYears = years.filter((year) => {
    //   console.log(
    //     "year a comprobar",
    //     year,
    //     stateYears,
    //     !stateYears.includes(year)
    //   );
    //   return !stateYears.includes(year);
    // });
    // console.log("solo van a comprobar estos años", downloadYears);
    // downloadYears.forEach((year) => {
    //   console.log("year to check", year);
    //   this.handleDownloadCompareYear(year);
    // });
    // this.setState({ compareYears: years });
  };

  handleDownloadCompareYear = (year) => {
    this.franchiseService
      .getFranchisesDashboardByYear(year)
      .then((response) => {
        const responseParsed = {
          ...createDashboardFranchises(response.data, year),
        };

        const compareDashboard = _.cloneDeep(this.state.compareDashboard);

        Object.keys(responseParsed.dashboard).forEach((keyCountry) => {
          if (compareDashboard[keyCountry]) {
            Object.keys(responseParsed.dashboard[keyCountry]).forEach(
              (keyProperty) => {
                if (keyProperty.includes("gmv_")) {
                  console.log(keyProperty);
                  compareDashboard[keyCountry][keyProperty] =
                    responseParsed.dashboard[keyCountry][keyProperty];
                }
              }
            );
            if (responseParsed.dashboard[keyCountry].invoices) {
              compareDashboard[keyCountry].invoices = [
                ...compareDashboard[keyCountry].invoices,
                ...responseParsed.dashboard[keyCountry].invoices,
              ];
            }
          } else {
            compareDashboard[keyCountry] = {};
            compareDashboard[keyCountry].invoices = [
              ...responseParsed.dashboard[keyCountry].invoices,
            ];
          }
        });
        console.log("final", year, compareDashboard);
        this.setState({
          compareDashboard: compareDashboard,
          history: responseParsed.history,
        });
        // this.setState({
        //   franchises: responseParsed.franchises,
        //   taxes: responseParsed.taxes,
        //   dashboard: { ...responseParsed.dashboard },
        // });
      })
      .catch((err) => {
        console.log("err get franchises", err);
      });
  };

  getInvoicePdf = (id) => {
    return this.invoiceService
      .getInvoicePdf(id)
      .then((response) => {
        return response.data;
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Grid item md={12}>
          <Franchises
            columns={this.context.translation.dataTables.franchises.columns}
            getInvoicePdf={this.getInvoicePdf}
            title={this.context.translation.dataTables.franchises.title}
            options={{
              textLabels: this.context.translation.dataTables.textLabels,
              selectableRows: "none",
            }}
            historyColumns={this.context.translation.dataTables.history.columns}
            historyData={this.state.history}
            data={this.state.franchises}
            taxes={this.state.taxes}
            dashboard={this.state.dashboard}
            compareDashboard={this.state.compareDashboard}
            handleSelectYears={this.handleSelectYears}
            createFranchise={this.createFranchise}
            editFranchise={this.editFranchise}
            language={this.context.language}
            year={this.state.year}
            handleChangeYear={this.handleChangeYear}
          />
        </Grid>
      </Grid>
    );
  }
}

export default FranchisesComponent;
