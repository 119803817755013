import React, { Component } from "react";
import { CentralTemplates as Templates } from "react-library-sm";
import { CssBaseline, Grid } from "@material-ui/core";
import TemplatesServices from "services/central-templates/templates.services";
import { Redirect } from "react-router-dom";

class TemplatesComponent extends Component {
  constructor(props) {
    super(props);
    this.service = new TemplatesServices();
    this.state = {
      templates: []
    };
    /*Auth.signOut()
      .then(data => console.log(data))
      .catch(err => console.log(err));*/
  }

  componentDidMount() {
    this.service
      .getTemplates()
      .then(response => {
        // console.log("templates", JSON.stringify(response.data));
        const templates = response.data.map((template, index) => {
          if (!template.languages) {
            return {
              ...template,
              languages: [],
              status: "draft",
              name: "template " + index,
              description: "",
              tags: []
            };
          }
          return {
            ...template,
            name: template.languages[0].name,
            description: "",
            tags: [],
            languages: template.languages.map(_template => {
              return { ..._template, language: _template.code };
            })
          };
        });
        console.log("templates final", templates);
        this.setState({ templates });
      })
      .catch(error => {
        console.error(error);
      });
  }

  handleAddTemplate = () => {
    this.service
      .addTemplate({
        type: "academy",
        status: "building",
        languages: [
          {
            code: "es",
            name: "Nueva template",
            status: "draft",
            description: "",
            details: "",
            tags: [],
            reviewed: false,
            isBestPractice: false,
            phases: [
              {
                name: "PLAN",
                order: 1,
                key: "plan",
                parent: "",
                children: [],
                daysToDeadline: -10,
                details: "",
                extraFields: []
              },

              {
                name: "DO & CHECK",
                order: 2,
                key: "docheck",
                parent: "",
                children: [],
                daysToDeadline: -10,
                details: "",
                extraFields: []
              },

              {
                name: "CLOSE",
                order: 3,
                key: "close",
                parent: "",
                children: [],
                daysToDeadline: -10,
                details: "",
                extraFields: []
              }
            ]
          }
        ]
      })
      .then(response => {
        this.setState({ newTemplate: response.data._id });
      });
  };

  render() {
    let redirect = this.state.newTemplate ? (
      <Redirect to={`/templates/${this.state.newTemplate}`} />
    ) : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px"
        }}
      >
        {redirect}
        <CssBaseline />
        <Templates
          templates={this.state.templates}
          handleAddTemplate={this.handleAddTemplate}
        />
      </Grid>
    );
  }
}

export default TemplatesComponent;
