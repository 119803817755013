import React, { Component } from "react";
import {
  CentralTemplate as Template,
  EditorState,
  ContentState,
  htmlToDraft,
} from "react-library-sm";
import { Grid } from "@material-ui/core";
import TemplatesServices from "services/central-templates/templates.services";
import { Redirect } from "react-router-dom";

class TemplateComponent extends Component {
  constructor(props) {
    super(props);
    this.service = new TemplatesServices();
    this.state = {
      type: "event",
      languages: null,
      status: "draft",
      category: "",
      loading: true,
    };
    this.child = React.createRef();
  }

  componentDidMount() {
    this.service
      .getTemplateLanguage(this.props.match.params.id, "es")
      .then((response) => {
        let template = response.data[0];
        let languages = {};
        response.data[0].keys.forEach((key) => {
          languages[key.code] = { status: key.status, downloaded: false };
        });
        languages.es = {
          ...languages.es,
          ...template.languages,
          detailsHtml: template.languages.details,
          detailsHtmlSaved: template.languages.details,
          detailsEditorStateSaved: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(template.languages.details).contentBlocks,
              htmlToDraft(template.languages.details).entityMap
            )
          ),
          detailsEditorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(template.languages.details).contentBlocks,
              htmlToDraft(template.languages.details).entityMap
            )
          ),
          phases: template.languages.phases.map((phase) => {
            return { ...phase, downloaded: false };
          }),
        };
        this.setState({
          languages,
          loading: false,
          type: template.type,
          status: template.status,
          isBestPractice: template.isBestPractice,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  handleDetailsEditorChangeLanguage = (content, html, language) => {
    // console.log("language handle", content, html, language);
    const languages = { ...this.state.languages };
    if (languages[language].detailsHtmlSaved === html) {
      languages[language].edited = false;
      languages[language].saved = true;
    } else {
      languages[language].edited = true;
      languages[language].saved = false;
    }
    languages[language] = {
      ...languages[language],
      detailsEditorState: content,
      detailsHtml: html,
    };

    this.setState({ languages });
  };

  handleSaveLanguage = (language) => {
    this.service
      .editLanguageDetails(
        this.props.match.params.id,
        language,
        this.state.languages[language].detailsHtml
      )
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language] = {
          ...languages[language],
          detailsEditorStateSaved: languages[language].detailsEditorState,
          detailsHtmlSaved: languages[language].detailsHtml,
          edited: false,
          saved: true,
          reviewed: false,
        };

        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleReviewLanguage = (language) => {
    this.service
      .reviewLanguage(this.props.match.params.id, language, true)
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language] = {
          ...languages[language],
          reviewed: true,
        };

        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleEditLanguage = (values, language) => {
    this.service
      .editLanguageInfo(this.props.match.params.id, language, {
        name: values.name,
        description: values.description,
      })
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language] = {
          ...languages[language],
          name: values.name,
          description: values.description,
        };

        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleDeleteLanguage = (language) => {
    this.service
      .deleteLanguage(this.props.match.params.id, language)
      .then((response) => {
        const languages = { ...this.state.languages };
        delete languages[language];
        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleAddLanguage = (values) => {
    const languages = { ...this.state.languages };
    // console.log(values);
    Promise.all(
      Object.keys(values).map((value) => {
        // console.log("value in central", values[value].editorState);
        let language = {
          ...values[value],
        };
        return this.service
          .addLanguage(this.props.match.params.id, language)
          .then((response) => {
            languages[value] = { ...values[value], downloaded: true };
            languages[value].detailsHtmlSaved = languages[value].details;
            languages[value].detailsHtml = languages[value].details;
            languages[value].detailsEditorStateSaved =
              EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  htmlToDraft(languages[value].details).contentBlocks,
                  htmlToDraft(languages[value].details).entityMap
                )
              );
            languages[value].detailsEditorState = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                htmlToDraft(languages[value].details).contentBlocks,
                htmlToDraft(languages[value].details).entityMap
              )
            );
            languages[value].phases = languages[value].phases.map((phase) => {
              return { ...phase, downloaded: true };
            });
          })
          .catch((err) => {
            alert(err);
          });
      })
    ).then((response) => {
      this.setState({ languages });
    });
  };

  handleRestartTemplate = () => {
    const esLanguage = this.state.languages.es;
    // console.log("handle restart", this.service);
    this.service
      .restartTemplate(this.props.match.params.id)
      .then((response) => {
        this.setState({
          languages: { es: esLanguage },
          status: response.data.status,
        });
      });
  };

  handleChangeStatusTemplate = (status) => {
    this.service
      .editTemplateStatus(this.props.match.params.id, status)
      .then((response) => {
        this.setState({ status });
      })
      .catch((err) => {
        alert(err);
      });
  };

  downloadLanguage = (language) => {
    this.service
      .getTemplateLanguage(this.props.match.params.id, language)
      .then((response) => {
        const languages = { ...this.state.languages };
        let languageDownload = response.data[0].languages;
        languages[language] = {
          ...languageDownload,
          downloaded: true,
          detailsHtml: languageDownload.details,
          detailsHtmlSaved: languageDownload.details,
          detailsEditorStateSaved: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(languageDownload.details).contentBlocks,
              htmlToDraft(languageDownload.details).entityMap
            )
          ),
          detailsEditorState: EditorState.createWithContent(
            ContentState.createFromBlockArray(
              htmlToDraft(languageDownload.details).contentBlocks,
              htmlToDraft(languageDownload.details).entityMap
            )
          ),
          phases: languageDownload.phases.map((phase) => {
            return { ...phase, downloaded: false };
          }),
        };

        this.setState({
          languages,
          isBestPractice: response.data[0].isBestPractice,
          type: response.data[0].type,
          status: response.data[0].status,
        });
      });
  };

  handleSubmitEditTagsLanguage = (values, language) => {
    this.service
      .editLanguageTags(this.props.match.params.id, language, {
        tags: values,
      })
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language].tags = values;
        // console.log("values tags", values.tags);
        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleSubmitEditType = (values) => {
    this.service
      .editTemplateType(this.props.match.params.id, values.type)
      .then((response) => {
        // console.log("values type", values.type);
        this.setState({ type: values.type });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleChangeStatusLanguage = (language, status) => {
    this.service
      .editLanguageStatus(this.props.match.params.id, language, status)
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language].status = status;
        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleDeleteTemplate = () => {
    this.service
      .deleteTemplate(this.props.match.params.id)
      .then((response) => {
        this.setState({ deleted: true });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleDeleteItem = (key, language) => {
    this.service
      .deleteLanguageTask(this.props.match.params.id, language, key)
      .then((response) => {
        const languages = { ...this.state.languages };

        languages[language].phases = response.data.map((phase) => {
          return { ...phase, downloaded: false };
        });
        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleEditItem = (item, language) => {
    this.service
      .putLanguageTask(this.props.match.params.id, language, item.key, item)
      .then((response) => {
        const languages = { ...this.state.languages };

        languages[language].phases = response.data.map((phase) => {
          return { ...phase, downloaded: false };
        });
        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleAddItem = (item, language) => {
    this.service
      .postLanguageTask(this.props.match.params.id, language, item)
      .then((response) => {
        const languages = { ...this.state.languages };

        languages[language].phases = response.data.map((phase) => {
          return { ...phase, downloaded: false };
        });
        this.setState({ languages });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleDownloadTask = (task, language) => {
    this.service
      .getLanguageTask(this.props.match.params.id, language, task)
      .then((response) => {
        const languages = { ...this.state.languages };
        languages[language].phases = languages[language].phases.map((_task) => {
          if (_task.key === task) {
            return { ...response.data, downloaded: true };
          } else {
            return { ..._task };
          }
        });

        this.setState({ languages }, () => {
          this.child.current.handleFromParent("open_dialog_edit_task", {
            task: task,
          });
        });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handleBestPracticeTemplate = (value) => {
    this.service
      .editTemplateBPractice(this.props.match.params.id, value)
      .then((response) => {
        this.setState({ isBestPractice: response.data });
      })
      .catch((err) => {
        alert(err);
      });
  };

  handlePreviousAddLanguage = (values) => {
    this.service
      .getFullTemplateLanguage(this.props.match.params.id, "es")
      .then((response) => {
        this.child.current.handleFromParent("handle_add_language", {
          values: values,
          language: response.data[0].languages,
        });
      });
  };

  render() {
    return (
      !this.state.loading && (
        <Grid
          container
          component="main"
          style={{
            padding: "4px 25px",
          }}
        >
          {this.state.deleted && <Redirect to="/templates" />}
          <Template
            type={this.state.type}
            tags={this.state.tags}
            isBestPractice={this.state.isBestPractice}
            languages={this.state.languages}
            handleRestartTemplate={this.handleRestartTemplate}
            handleAddLanguage={this.handleAddLanguage}
            handleChangeStatusLanguage={this.handleChangeStatusLanguage}
            handleChangeStatusTemplate={this.handleChangeStatusTemplate}
            handleSaveLanguage={this.handleSaveLanguage}
            handleReviewLanguage={this.handleReviewLanguage}
            handleEditLanguage={this.handleEditLanguage}
            handleDeleteLanguage={this.handleDeleteLanguage}
            handleDeleteTemplate={this.handleDeleteTemplate}
            downloadLanguage={this.downloadLanguage}
            handleSubmitEditTagsLanguage={this.handleSubmitEditTagsLanguage}
            handleDetailsEditorChangeLanguage={
              this.handleDetailsEditorChangeLanguage
            }
            handleBestPracticeTemplate={this.handleBestPracticeTemplate}
            handleSubmitEditType={this.handleSubmitEditType}
            handleAddItem={this.handleAddItem}
            handleEditItem={this.handleEditItem}
            handleDeleteItem={this.handleDeleteItem}
            handleDownloadTask={this.handleDownloadTask}
            handlePreviousAddLanguage={this.handlePreviousAddLanguage}
            status={this.state.status}
            server={process.env.REACT_APP_API_CENTRAL_PROJECTS}
            ref={this.child}
          />
        </Grid>
      )
    );
  }
}

export default TemplateComponent;
