import Axios from "core/axios";
import FranchiseServices from "services/franchises.services.js";

class UsersServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_AUTH;
    this.service = new Axios(this.api_url);
    this.franchiseService = new FranchiseServices();
  }

  getUsers = () => {
    return this.service.get("/users");
  };

  postUser = (data) => {
    // console.log('data en postuser', data);
    return this.service.post("/users", data);
  };
  putUser = (data) => {
    // console.log('data en postuser', data);
    return this.service.put(`/users/${data._id}`, data);
  };

  createUsers = (users, franchise) => {
    // console.log('users', users);
    // console.log('franchise', franchise);

    if (users.length === 1) {
      const _user = {
        ...users[0],
        franchises: [{ id: franchise._id, rol: "franchise" }],
      };
      return this.postUser(_user).then((response) => {
        // console.log('response users 1', response);

        const _franchise = { ...franchise };
        _franchise.users.push(response.data._id);
        return this.franchiseService.putFranchise(_franchise);
      });
    } else {
      const _users = users.map((user) => {
        return {
          ...user,
          franchises: [{ id: franchise._id, rol: "franchise" }],
        };
      });
      return Promise.all(
        _users.map((user) => {
          return this.postUser(user);
        })
      ).then((response) => {
        const _franchise = { ...franchise };
        response.forEach((res) => {
          _franchise.users.push(res.data._id);
        });

        return this.franchiseService.putFranchise(_franchise);
      });
    }
  };
}

export default UsersServices;
