// import users from "assets/db/users";
import React from "react";
import { SMPopover, Chip, Typography, numeral } from "react-library-sm";
import moment from "moment";
import RemoveRedEye from "@material-ui/icons/RemoveRedEye";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import StopIcon from "@material-ui/icons/Stop";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheckAlt as transfer,
  faPaperPlane as sent,
} from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane as pendingSent } from "@fortawesome/free-regular-svg-icons";

import { faStripe as stripe } from "@fortawesome/free-brands-svg-icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const translation = {
  service_type: {
    event: "Event",
    academy: "Academy",
    campus: "Camp",
  },
  statusSells: ["Lead", "Proposal", "Offer", "On Hold", "Won", "Lost"],
  statusExecution: {
    plan: "Plan",
    docheck: "Do&Check",
    close: "Close",
  },
  tasks: {
    status: {
      pending: "Pending",
      done: "Done",
    },
    type: {
      call: "Call",
      email: "Email",
      visit: "Visit",
      meeting: "Meeting",
    },
    opportunity_status: {
      lead: "Lead",
      offer: "Offer",
      proposal: "Proposal",
      hibernate: "On hold",
    },
  },
  invoices: {
    status: {
      pending: "Pending",
      overdue: "Overdue",
      amended: "Amended",
      amending: "Amending",
      uploaded_to_face: "Uploaded to FACE",
      uncollectable: "Uncollectable",
      draft: "Draft",
      payment_failed: "Payment failed",
      open: "Open",
      paid: "Paid",
    },
    sent: {
      true: "Yes",
      false: "No",
    },
    categories: {
      initial_fee: "Initial Fee",
      recurring_fee: "Recurring Fee",
      office: "Office",
      marketing: "Marketing",
      others: "Others",
      events: "Events",
      campus: "Camps",
      academies: "Academies",
      marketplace: "Marketplace Fees",
    },
  },
};

const language = {
  locale: "es-ES",
  sideDrawer: [
    { primary: { name: "Franchises", url: "/franchises" } },
    { primary: { name: "Invoices", url: "/invoices" } },
    { primary: { name: "Subscriptions", url: "/subscriptions" } },
    { primary: { name: "Office", url: "/office" } },
    // {
    //   primary: { name: "CRM", url: "/crm" },
    //   secondary: [
    //     { name: "Contacts", url: "/contacts" },
    //     // { name: "Pipeline", url: '/pipeline' },
    //   ],
    // },
    // {
    //   primary: { name: "CRM", url: "/crm" },
    //   secondary: [
    //     { name: "Contacts", url: "/contacts" }
    //     // { name: "Pipeline", url: '/pipeline' },
    //   ]
    // },
    {
      primary: { name: "Project Templates", url: "/templates" },
    },
    { primary: { name: "Team", url: "/users" } },
    { primary: { name: "Academy", url: "/academy/courses" } },
    { primary: { name: "Billing settings", url: "/billing-settings" } },
  ],
  dataTables: {
    textLabels: {
      body: {
        noMatch: "Lo sentimos, no hay resultados",
        toolTip: "Ordenar",
      },
      pagination: {
        next: "Siguente",
        previous: "Anterior",
        rowsPerPage: "Filas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Descargar CSV",
        print: "Imprimir",
        viewColumns: "Ver Columnas",
        filterTable: "Filtrar Tabla",
      },
      filter: {
        all: "Todos",
        title: "FILTROS",
        reset: "RESETEAR",
      },
      viewColumns: {
        title: "Mostrar Columnas",
        titleAria: "Mostrar/Ocultar Columnas de la tabla",
      },
      selectedRows: {
        text: "Columna(s) seleccionadas",
        delete: "Borrar",
        deleteAria: "Borrar Columnas Seleccionadas",
      },
    },
    history: {
      columns: [
        {
          label: "id",
          name: "id",
          options: { display: false, filter: false },
        },
        {
          label: "History date",
          name: "history_date",
          options: { filter: false },
        },
        { label: "Nº Invoice", name: "docnumber", options: { filter: false } },
        {
          label: "Franchise",
          name: "franchiseName",
          options: { filter: false, sort: false },
        },
        {
          label: "Client",
          name: "clientName",
          options: { filter: false, sort: false },
        },
        {
          label: "Country",
          name: "country",
        },
        {
          label: "Invoice date",
          name: "date",
          options: { filter: false },
        },
        {
          label: "Subtotal",
          name: "subtotal",
          options: {
            filter: false,
          },
        },
        {
          label: "Tax",
          name: "tax",
          options: {
            filter: false,
          },
        },
        {
          label: "Total",
          name: "total",
          options: {
            filter: false,
          },
        },
        { label: "", name: "options", options: {} },
      ],
    },
    franchises: {
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Franchise",
          name: "name",
        },
        {
          label: "#Users",
          name: "users",
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            empty: true,
            label: false,
            customBodyRender: (value, tableMeta, updateValue) => (
              <SMPopover data={tableMeta.rowData} type="franchises" />
            ),
          },
        },
      ],
      title: "Franchises",
    },
    users: {
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false },
        },
        {
          label: "Name",
          name: "name",
        },
        {
          label: "E-mail",
          name: "email",
        },

        {
          label: "",
          name: "options",
          options: {
            filter: true,
            sort: false,
            empty: true,
            label: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return <SMPopover data={tableMeta.rowData} type="users" />;
            },
          },
        },
      ],
      title: "Users",
    },
    opportunities: {
      title: "Opportunities",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Name",
          name: "name",
          options: { filter: false },
        },
        {
          label: "Start date",
          name: "date",
          options: {
            // 	filter: true,
            // 	filterType: 'custom',
            // 	customFilterListRender: (v) => {
            // 		const values = v.map((_v) => moment(_v).format('DD-MM-YYYY HH:mm'));
            // 		if (v[0] && v[1]) {
            // 			return `${translation.contacts.single.datatables.opportunities.date
            // 				.from} ${values[0]}, ${translation.contacts.single.datatables.opportunities.date
            // 				.to} ${values[1]}`;
            // 		} else if (values[0]) {
            // 			return `${translation.contacts.single.datatables.opportunities.date.from} ${values[0]}`;
            // 		} else if (values[1]) {
            // 			return `${translation.contacts.single.datatables.opportunities.date.to} ${values[1]}`;
            // 		}
            // 		return false;
            // 	},
            // 	filterOptions: {
            // 		names: [],
            // 		logic(date, filters) {
            // 			const momFilt = filters.map((filt) => moment(filt, 'DD-MM-YYYY HH:mm'));
            // 			const momDate = moment(date, 'DD-MM-YYYY HH:mm');
            // 			let show = true;

            // 			if (filters[0] && filters[1]) {
            // 				show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
            // 			} else if (filters[0]) {
            // 				show = momDate.isSameOrAfter(momFilt[0]);
            // 			} else if (filters[1]) {
            // 				show = momDate.isSameOrBefore(momFilt[1]);
            // 			}
            // 			return !show;
            // 		},
            // 		display: (filterList, onChange, index, column) => {
            // 			// return <RangeDatePicker
            // 			//   time
            // 			//   translation={translation.opportunities.single.datatables.tasks.date ? translation.opportunities.single.datatables.tasks.date: null}
            // 			//   values={filterList}
            // 			//   onChange={onChange}
            // 			//   index={index}
            // 			//   column={column}
            // 			// />
            // 			return [
            // 				<MuiPickersUtilsProvider utils={MomentUtils} locale="es">
            // 					<KeyboardDatePicker
            // 						ampm={false}
            // 						clearable
            // 						label={translation.contacts.single.datatables.opportunities.date.from}
            // 						format="DD/MM/YYY"
            // 						value={filterList[index][0] || null}
            // 						onChange={(event) => {
            // 							filterList[index][0] = moment(event, 'DD/MM/YYY');
            // 							onChange(filterList[index], index, column);
            // 						}}
            // 						style={{ marginRight: '5%' }}
            // 					/>
            // 					<KeyboardDatePicker
            // 						ampm={false}
            // 						clearable
            // 						label={translation.contacts.single.datatables.opportunities.date.to}
            // 						format="DD/MM/YYY"
            // 						value={filterList[index][1] || null}
            // 						onChange={(event) => {
            // 							filterList[index][1] = moment(event, 'DD/MM/YYY');
            // 							onChange(filterList[index], index, column);
            // 						}}
            // 					/>
            // 				</MuiPickersUtilsProvider>
            // 			];
            // 		}
            // 	},
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Service type",
          name: "type",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Service type ${v}`,
            filterOptions: {
              names: Object.values(translation.service_type),
              logic(type, filterVal) {
                const show =
                  (filterVal.indexOf(translation.service_type.event) >= 0 &&
                    type === "event") ||
                  (filterVal.indexOf(translation.service_type.academy) >= 0 &&
                    type === "academy") ||
                  (filterVal.indexOf(translation.service_type.campus) >= 0 &&
                    type === "campus");
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => (
              <Chip label={translation.service_type[tableMeta.rowData[3]]} />
            ),
          },
        },
        {
          label: "Client",
          name: "client",
          options: {
            filter: false,
          },
        },
        {
          label: "Budget",
          name: "budget",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[5]).format("0,0[.]00 $"),
          },
        },
        {
          label: "Status",
          name: "statusSells",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Estado ${v}`,
            filterOptions: {
              names: translation.statusSells.map((lab) => lab),
              logic(status, filterVal) {
                const show =
                  filterVal.indexOf(translation.statusSells[status]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip
                  label={translation.statusSells[tableMeta.rowData[6]]}
                  style={
                    tableMeta.rowData[6] === 4
                      ? tableMeta.rowData[6] === 5
                        ? {
                            background: "#E95A58",
                            color: "#616161",
                          }
                        : {
                            background: "#77ffb5",
                            color: "#616161",
                          }
                      : {
                          background: "#ffe082",
                          color: "#616161",
                        }
                  }
                />
              );
            },
          },
        },
        {
          label: "franchise",
          name: "franchise",
          options: { display: false, filter: false },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Link
                  to={`/franchises/${tableMeta.rowData[7]}/pipeline/${tableMeta.rowData[0]}/view`}
                  style={{ color: "#fff" }}
                >
                  <RemoveRedEye />
                </Link>
              );
            },
          },
        },
      ],
    },
    projects: {
      title: "Projects",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Name",
          name: "infoProject.name",
          options: { filter: false },
        },
        {
          label: "Date",
          name: "date",
          options: {
            filter: true,
            filterType: "custom",
            customFilterListRender: (v) => {
              const values = v.map((_v) =>
                moment(_v).format("DD-MM-YYYY HH:mm")
              );
              if (v[0] && v[1]) {
                return `From ${values[0]}, To ${values[1]}`;
              } else if (values[0]) {
                return `From ${values[0]}`;
              } else if (values[1]) {
                return `To ${values[1]}`;
              }
              return false;
            },
            filterOptions: {
              names: [],
              logic(date, filters) {
                const momFilt = filters.map((filt) =>
                  moment(filt, "DD-MM-YYYY HH:mm")
                );
                const momDate = moment(date, "DD-MM-YYYY HH:mm");
                let show = true;

                if (filters[0] && filters[1]) {
                  show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                } else if (filters[0]) {
                  show = momDate.isSameOrAfter(momFilt[0]);
                } else if (filters[1]) {
                  show = momDate.isSameOrBefore(momFilt[1]);
                }
                return !show;
              },
              display: (filterList, onChange, index, column) => {
                return [
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label={"Desde"}
                      // label={translation.contacts.single.datatables.projects.date.from}
                      format="DD/MM/YYY"
                      value={filterList[index][0] || null}
                      onChange={(event) => {
                        filterList[index][0] = moment(event, "DD/MM/YYY");
                        onChange(filterList[index], index, column);
                      }}
                      style={{ marginRight: "5%" }}
                    />
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label={"Hasta"}
                      format="DD/MM/YYY"
                      value={filterList[index][1] || null}
                      onChange={(event) => {
                        filterList[index][1] = moment(event, "DD/MM/YYY");
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </MuiPickersUtilsProvider>,
                ];
              },
            },
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Service type",
          name: "infoProject.type",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Service type: ${v}`,
            filterOptions: {
              names: Object.values(translation.service_type),
              logic(type, filterVal) {
                const show =
                  filterVal.indexOf(translation.service_type[type]) >= 0;
                // (filterVal.indexOf(translation.service_type.event) >= 0 && type === 'event') ||
                // (filterVal.indexOf(translation.service_type.academy) >= 0 && type === 'academy') ||
                // (filterVal.indexOf(translation.service_type.campus) >= 0 && type === 'campus');
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip label={translation.service_type[tableMeta.rowData[3]]} />
              );
            },
          },
        },
        {
          label: "Client",
          name: "client.0.name",
          options: { display: true, filter: false },
        },
        {
          label: "Status",
          name: "statusExecution",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Status ${v}`,
            filterOptions: {
              names: Object.values(translation.statusExecution),
              logic(status, filterVal) {
                const show =
                  filterVal.indexOf(translation.statusExecution[status]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => (
              <Chip label={translation.statusExecution[tableMeta.rowData[5]]} />
            ),
          },
        },
        {
          label: "franchise",
          name: "franchise",
          options: { display: false, filter: false },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log('tableMeta franchise', tableMeta.rowData[6])
              return (
                <Link
                  to={`/franchises/${tableMeta.rowData[6]}/project/${tableMeta.rowData[0]}/view`}
                  style={{ color: "#fff" }}
                >
                  <RemoveRedEye />
                </Link>
              );
            },
          },
        },
      ],
    },
    tasks: {
      title: "Tasks",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Subject",
          name: "subject",
          options: { filter: false },
        },
        {
          label: "Date",
          name: "date",
          options: {
            filter: true,
            filterType: "custom",
            customFilterListRender: (v) => {
              const values = v.map((_v) =>
                moment(_v).format("DD-MM-YYYY HH:mm")
              );
              if (v[0] && v[1]) {
                return `From ${values[0]}, To ${values[1]}`;
              } else if (values[0]) {
                return `From ${values[0]}`;
              } else if (values[1]) {
                return `To ${values[1]}`;
              }
              return false;
            },
            filterOptions: {
              names: [],
              logic(date, filters) {
                const momFilt = filters.map((filt) =>
                  moment(filt, "DD-MM-YYYY HH:mm")
                );
                const momDate = moment(date, "DD-MM-YYYY HH:mm");
                let show = true;

                if (filters[0] && filters[1]) {
                  show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                } else if (filters[0]) {
                  show = momDate.isSameOrAfter(momFilt[0]);
                } else if (filters[1]) {
                  show = momDate.isSameOrBefore(momFilt[1]);
                }
                return !show;
              },
              display: (filterList, onChange, index, column) => {
                // return <RangeDatePicker
                //   time
                //   translation={translation.contacts.single.datatables.tasks.date ? translation.contacts.single.datatables.tasks.date: null}
                //   values={filterList}
                //   onChange={onChange}
                //   index={index}
                //   column={column}
                // />
                return [
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label={"From"}
                      format="DD/MM/YYY"
                      value={filterList[index][0] || null}
                      onChange={(event) => {
                        filterList[index][0] = moment(event, "DD/MM/YYY");
                        onChange(filterList[index], index, column);
                      }}
                      style={{ marginRight: "5%" }}
                    />
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label={"To"}
                      format="DD/MM/YYY"
                      value={filterList[index][1] || null}
                      onChange={(event) => {
                        filterList[index][1] = moment(event, "DD/MM/YYY");
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </MuiPickersUtilsProvider>,
                ];
              },
            },
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Person of contact",
          name: "person_of_contact",
          options: {
            filter: false,
          },
        },

        {
          label: "Type",
          name: "type",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Tipo ${v}`,
            filterOptions: {
              names: Object.values(translation.tasks.type),
              logic(type, filterVal) {
                const show =
                  filterVal.indexOf(translation.tasks.type[type]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => (
              <Typography variant="body2" gutterBottom>
                {translation.tasks.type[tableMeta.rowData[4]]}
              </Typography>
            ),
          },
        },
        {
          label: "Status",
          name: "status",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Estado ${v}`,
            filterOptions: {
              names: Object.values(translation.tasks.status),
              logic(status, filterVal) {
                const show =
                  filterVal.indexOf(translation.tasks.status[status]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => (
              <Chip
                label={translation.tasks.status[tableMeta.rowData[5]]}
                style={
                  tableMeta.rowData[5] === "done"
                    ? {
                        background: "#77ffb5",
                        color: "#616161",
                      }
                    : {
                        background: "#ffe082",
                        color: "#616161",
                      }
                }
              />
            ),
          },
        },
        {
          label: "Opportunity",
          name: "opportunity_status",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Estado de la oportunidad ${v}`,
            filterOptions: {
              names: Object.values(translation.tasks.opportunity_status),
              logic(opportunity_status, filterVal) {
                const show =
                  filterVal.indexOf(
                    translation.tasks.opportunity_status[opportunity_status]
                  ) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => (
              <Chip
                label={
                  translation.tasks.opportunity_status[tableMeta.rowData[6]]
                }
              />
            ),
          },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: false,
          },
        },
      ],
    },
    invoices: {
      title: "Invoices",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Date",
          name: "date",
          options: {
            filter: true,
            filterType: "custom",
            customFilterListRender: (v) => {
              const values = v.map((_v) => moment(_v).format("DD-MM-YYYY"));
              if (v[0] && v[1]) {
                return `From ${values[0]}, To ${values[1]}`;
              } else if (values[0]) {
                return `From ${values[0]}`;
              } else if (values[1]) {
                return `To ${values[1]}`;
              }
              return false;
            },
            filterOptions: {
              names: [],
              logic(date, filters) {
                const momFilt = filters.map((filt) =>
                  moment(filt, "DD-MM-YYYY")
                );
                const momDate = moment(date, "DD-MM-YYYY");
                let show = true;

                if (filters[0] && filters[1]) {
                  show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                } else if (filters[0]) {
                  show = momDate.isSameOrAfter(momFilt[0]);
                } else if (filters[1]) {
                  show = momDate.isSameOrBefore(momFilt[1]);
                }
                return !show;
              },
              display: (filterList, onChange, index, column) => {
                return [
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label={"From"}
                      format="DD/MM/YYYY"
                      value={filterList[index][0] || null}
                      onChange={(event) => {
                        filterList[index][0] = moment(event, "DD/MM/YYYY");
                        onChange(filterList[index], index, column);
                      }}
                      style={{ marginRight: "5%" }}
                    />
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label="To"
                      format="DD/MM/YYYYY"
                      value={filterList[index][1] || null}
                      onChange={(event) => {
                        filterList[index][1] = moment(event, "DD/MM/YYYY");
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </MuiPickersUtilsProvider>,
                ];
              },
            },
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Num",
          name: "docnumber",
          options: {
            filter: false,
          },
        },
        {
          label: "Franchise",
          name: "franchise.name",
          options: {
            filter: false,
          },
        },
        {
          label: "Status",
          name: "status",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Status ${v}`,
            filterOptions: {
              names: Object.values(translation.invoices.status),
              logic(status, filterVal) {
                const show =
                  filterVal.indexOf(translation.invoices.status[status]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip
                  label={translation.invoices.status[tableMeta.rowData[4]]}
                  style={
                    tableMeta.rowData[4] === "pending"
                      ? {
                          borderRadius: "5px",
                          background: "transparent",
                          border: "1px solid #ffe082",
                          color: "#ffe082",
                        }
                      : tableMeta.rowData[4] === "paid"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #77ffb5",
                          color: "#77ffb5",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "payment_failed" ||
                        tableMeta.rowData[4] === "open" ||
                        tableMeta.rowData[4] === "overdue"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #E95A58",
                          color: "#fff",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "draft"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #999",
                          color: "#fff",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "amended"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #28e2ab",
                          color: "#28e2ab",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "uncollectable"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #999",
                          color: "#fff",
                          background: "transparent",
                        }
                      : {
                          borderRadius: "5px",
                          border: "1px solid #80b9d6",
                          color: "#80b9d6",
                          background: "transparent",
                        }
                  }
                />
              );
            },
          },
        },
        {
          label: "Category",
          name: "category",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Category ${v}`,
            filterOptions: {
              names: [
                "Initial fee",
                "Recurring Fee",
                "Marketing",
                "Office",
                "Other",
              ],
              logic(category, filterVal) {
                const show =
                  filterVal.indexOf(
                    translation.invoices.categories[category]
                  ) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip
                  label={translation.invoices.categories[tableMeta.rowData[5]]}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #fff",
                    color: "#fff",
                    background: "transparent",
                  }}
                />
              );
            },
          },
        },
        {
          label: "Subtotal",
          name: "subtotal",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[6]).format("0,0 $"),
          },
        },
        {
          label: "Tax",
          name: "tax",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[7]).format("0,0$"),
          },
        },
        {
          label: "Total",
          name: "total",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[8]).format("0,0 $"),
          },
        },
        {
          label: "Sent",
          name: "sent",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Sent: ${v}`,
            filterOptions: {
              names: Object.values(translation.invoices.sent),
              logic(sent, filterVal) {
                const show =
                  filterVal.indexOf(translation.invoices.sent[sent]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) =>
              tableMeta.rowData[9] ? (
                <FontAwesomeIcon
                  icon={sent}
                  style={{ "font-size": "20px", color: "#77ffb5" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={pendingSent}
                  style={{ "font-size": "20px", color: "#ffe082" }}
                />
              ),
          },
        },
        {
          label: "",
          name: "options",
        },
      ],
      options: {},
    },
    payment_methods: {
      title: "Payment methods",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Name",
          name: "name",
        },
        {
          label: "Type",
          name: "type",
        },
        {
          label: "Account Number",
          name: "account_number",
        },
        {
          label: "",
          name: "options",
        },
      ],
    },
    series: {
      title: "Series",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Name",
          name: "name",
        },
        {
          label: "Prefix",
          name: "prefix",
        },
        {
          label: "Sufix",
          name: "sufix",
        },
        {
          label: "Last Number",
          name: "lastInvoice",
        },
        {
          label: "",
          name: "options",
        },
      ],
    },
    subscriptions: {
      title: "Subscriptions",

      columns: [
        {
          label: "key",
          name: "subscription.key",
          options: { display: false, filter: false },
        },
        {
          label: "Franchise",
          name: "name",
        },
        {
          label: "Start date",
          name: "subscription.start_date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[2]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Next invoice",
          name: "next_invoice",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[3]).format("DD/MM/YYYY"),
          },
        },
        // {
        //   label: "Last invoice",
        //   name: "last_invoice",
        //   options: {
        //     customBodyRender: (value, tableMeta, updateValue) =>
        //       numeral(tableMeta.rowData[4]).format("0,0[.]00 $")
        //   }
        // },
        {
          label: "Payment Method",
          name: "subscription.stripe",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              tableMeta.rowData[4] ? (
                <FontAwesomeIcon
                  icon={stripe}
                  style={{ "font-size": "40px" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={transfer}
                  style={{ "font-size": "20px" }}
                />
              ),
          },
        },
        {
          label: "Status",
          name: "subscription.status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              tableMeta.rowData[5] === "active" ? (
                <AutorenewIcon style={{ color: "#77ffb5" }} />
              ) : (
                <StopIcon style={{ color: "#E95A58" }} />
              ),
          },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log('tableMeta franchise', tableMeta.rowData[6])
              return (
                <Link
                  to={`/subscriptions/${tableMeta.rowData[0]}`}
                  style={{ color: "#fff" }}
                >
                  <RemoveRedEye />
                </Link>
              );
            },
          },
        },
      ],
    },
    subscription_invoices: {
      title: "Invoices",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Date",
          name: "date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Num",
          name: "docnumber",
          options: { filter: false },
        },
        {
          label: "Due date",
          name: "due_date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[3]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Subtotal",
          name: "subtotal",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[4]).format("0,0[.]00 $"),
          },
        },
        {
          label: "Tax",
          name: "tax",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[5]).format("0,0[.]00 $"),
          },
        },
        {
          label: "Total",
          name: "total",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[6]).format("0,0[.]00 $"),
          },
        },
        {
          label: "Status",
          name: "status",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Status ${v}`,
            filterOptions: {
              names: Object.values(translation.invoices.status),
              logic(status, filterVal) {
                const show =
                  filterVal.indexOf(translation.invoices.status[status]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip
                  label={translation.invoices.status[tableMeta.rowData[7]]}
                  style={
                    tableMeta.rowData[7] === "pending"
                      ? {
                          borderRadius: "5px",
                          background: "transparent",
                          border: "1px solid #ffe082",
                          color: "#ffe082",
                        }
                      : tableMeta.rowData[7] === "paid"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #77ffb5",
                          color: "#77ffb5",
                          background: "transparent",
                        }
                      : tableMeta.rowData[7] === "payment_failed" ||
                        tableMeta.rowData[7] === "open" ||
                        tableMeta.rowData[7] === "overdue"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #E95A58",
                          color: "#fff",
                          background: "transparent",
                        }
                      : tableMeta.rowData[7] === "draft"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #999",
                          color: "#fff",
                          background: "transparent",
                        }
                      : tableMeta.rowData[7] === "amended"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #28e2ab",
                          color: "#28e2ab",
                          background: "transparent",
                        }
                      : {
                          borderRadius: "5px",
                          border: "1px solid #80b9d6",
                          color: "#80b9d6",
                          background: "transparent",
                        }
                  }
                />
              );
            },
          },
        },
        {
          label: "Sent",
          name: "sent",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Sent: ${v}`,
            filterOptions: {
              names: Object.values(translation.invoices.sent),
              logic(sent, filterVal) {
                const show =
                  filterVal.indexOf(translation.invoices.sent[sent]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) =>
              tableMeta.rowData[8] ? (
                <FontAwesomeIcon
                  icon={sent}
                  style={{ "font-size": "20px", color: "#77ffb5" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={pendingSent}
                  style={{ "font-size": "20px", color: "#ffe082" }}
                />
              ),
          },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log('tableMeta franchise', tableMeta.rowData[6])
              return (
                <Link
                  to={`/invoices/${tableMeta.rowData[0]}`}
                  style={{ color: "#fff" }}
                >
                  <RemoveRedEye />
                </Link>
              );
            },
          },
        },
      ],
    },
    subscription_forecast_invoices: {
      title: "Next Invoices",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Date",
          name: "date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Amount",
          name: "amount",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[2]).format("0,0[.]00 $"),
          },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: true,
          },
        },
      ],
    },
    office: {
      title: "Office",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Franchise",
          name: "name",
        },
        {
          label: "Office 365",
          name: "subscriptions.office365",
        },
        {
          label: "Mailbox",
          name: "subscriptions.mailbox",
        },
        {
          label: "Status",
          name: "subscriptions.status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) =>
              tableMeta.rowData[4] === "active" ? (
                <AutorenewIcon style={{ color: "#77ffb5" }} />
              ) : (
                <StopIcon style={{ color: "#E95A58" }} />
              ),
          },
        },
        {
          label: "",
          name: "options",
          options: {
            filter: false,
            sort: false,
            viewColumns: false,
            empty: true,
            label: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              // console.log('tableMeta franchise', tableMeta.rowData[6])
              return (
                <Link
                  to={`/office/${tableMeta.rowData[0]}`}
                  style={{ color: "#fff" }}
                >
                  <RemoveRedEye />
                </Link>
              );
            },
          },
        },
      ],
    },
    franchise_invoices: {
      title: "Invoices",
      columns: [
        {
          label: "id",
          name: "_id",
          options: { display: false, filter: false },
        },
        {
          label: "Date",
          name: "date",
          options: {
            filter: true,
            filterType: "custom",
            customFilterListRender: (v) => {
              const values = v.map((_v) => moment(_v).format("DD-MM-YYYY"));
              if (v[0] && v[1]) {
                return `From ${values[0]}, To ${values[1]}`;
              } else if (values[0]) {
                return `From ${values[0]}`;
              } else if (values[1]) {
                return `To ${values[1]}`;
              }
              return false;
            },
            filterOptions: {
              names: [],
              logic(date, filters) {
                const momFilt = filters.map((filt) =>
                  moment(filt, "DD-MM-YYYY")
                );
                const momDate = moment(date, "DD-MM-YYYY");
                let show = true;

                if (filters[0] && filters[1]) {
                  show = momDate.isBetween(momFilt[0], momFilt[1], null, []);
                } else if (filters[0]) {
                  show = momDate.isSameOrAfter(momFilt[0]);
                } else if (filters[1]) {
                  show = momDate.isSameOrBefore(momFilt[1]);
                }
                return !show;
              },
              display: (filterList, onChange, index, column) => {
                return [
                  <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label={"From"}
                      format="DD/MM/YYYY"
                      value={filterList[index][0] || null}
                      onChange={(event) => {
                        filterList[index][0] = moment(event, "DD/MM/YYYY");
                        onChange(filterList[index], index, column);
                      }}
                      style={{ marginRight: "5%" }}
                    />
                    <KeyboardDatePicker
                      ampm={false}
                      clearable
                      label="To"
                      format="DD/MM/YYYYY"
                      value={filterList[index][1] || null}
                      onChange={(event) => {
                        filterList[index][1] = moment(event, "DD/MM/YYYY");
                        onChange(filterList[index], index, column);
                      }}
                    />
                  </MuiPickersUtilsProvider>,
                ];
              },
            },
            customBodyRender: (value, tableMeta, updateValue) =>
              moment(tableMeta.rowData[1]).format("DD/MM/YYYY"),
          },
        },
        {
          label: "Num",
          name: "docnumber",
          options: {
            filter: false,
          },
        },
        {
          label: "Client",
          name: "client.name",
          options: {
            filter: false,
          },
        },
        {
          label: "Status",
          name: "status",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Status ${v}`,
            filterOptions: {
              names: Object.values(translation.invoices.status),
              logic(status, filterVal) {
                const show =
                  filterVal.indexOf(translation.invoices.status[status]) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip
                  label={translation.invoices.status[tableMeta.rowData[4]]}
                  style={
                    tableMeta.rowData[4] === "pending"
                      ? {
                          borderRadius: "5px",
                          background: "transparent",
                          border: "1px solid #ffe082",
                          color: "#ffe082",
                        }
                      : tableMeta.rowData[4] === "paid"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #77ffb5",
                          color: "#77ffb5",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "payment_failed" ||
                        tableMeta.rowData[4] === "open" ||
                        tableMeta.rowData[4] === "overdue"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #E95A58",
                          color: "#fff",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "draft"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #999",
                          color: "#fff",
                          background: "transparent",
                        }
                      : tableMeta.rowData[4] === "amended"
                      ? {
                          borderRadius: "5px",
                          border: "1px solid #28e2ab",
                          color: "#28e2ab",
                          background: "transparent",
                        }
                      : {
                          borderRadius: "5px",
                          border: "1px solid #80b9d6",
                          color: "#80b9d6",
                          background: "transparent",
                        }
                  }
                />
              );
            },
          },
        },
        {
          label: "Category",
          name: "category",
          options: {
            filter: true,
            filterType: "checkbox",
            customFilterListRender: (v) => `Category ${v}`,
            filterOptions: {
              names: [
                "Initial fee",
                "Recurring Fee",
                "Marketing",
                "Office",
                "Other",
              ],
              logic(category, filterVal) {
                const show =
                  filterVal.indexOf(
                    translation.invoices.categories[category]
                  ) >= 0;
                return !show;
              },
            },
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Chip
                  label={translation.invoices.categories[tableMeta.rowData[5]]}
                  style={{
                    borderRadius: "5px",
                    border: "1px solid #fff",
                    color: "#fff",
                    background: "transparent",
                  }}
                />
              );
            },
          },
        },
        {
          label: "Subtotal",
          name: "subtotal",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[6]).format("0,0$"),
          },
        },
        {
          label: "Tax",
          name: "tax",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[7]).format("0,0$"),
          },
        },
        {
          label: "Total",
          name: "total",
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) =>
              numeral(tableMeta.rowData[8]).format("0,0$"),
          },
        },

        {
          label: "",
          name: "options",
        },
      ],
      options: {},
    },
  },
  statusSells: ["Lead", "Proposal", "Offer", "On Hold", "Won", "Lost"],
  service_type: {
    event: "Event",
    academy: "Academy",
    campus: "Camp",
  },
};

// console.log(language);

export { language };
