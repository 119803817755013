import Axios from "core/axios";

class TemplatesServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_PROJECTS;
    this.service = new Axios(this.api_url);
  }

  getTemplates = () => {
    // console.log("en getTemplates");
    return this.service.get("/templates?language=es");
  };

  postTemplate = (data) => {
    return this.service.post("/templates", data);
  };

  getTemplate = (id, language) => {
    if (language === undefined) {
      language = "es";
    }
    return this.service.get(`/templates/${id}?language=${language}`);
  };

  putTemplate = (data) => {
    return this.service.put(`/templates/${data._id}`, data);
  };

  putTemplateLanguages = (data) => {
    return this.service.put(`/templates/${data._id}/languages`, data);
  };

  deleteTemplate = (id) => {
    return this.service.delete(`/templates/${id}`);
  };
}

export default TemplatesServices;
