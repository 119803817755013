// DATA

import _ from "lodash";
import moment from "moment";
import momentTz from "moment-timezone";
import { numeral } from "react-library-sm";

const { countryCurrency } = require("./currency");

const convertInvoiceToEur = (invoice, currency) => ({
  ...invoice,
  subtotal: invoice.subtotal * currency,
});

// { dashbooard: {}, franchises: [], taxes: [] }
export const createDashboardFranchises = (data, year) => {
  const newDashboard = createDashboardAllCountries(data.dashboard, year);
  const history = createHistory({
    invoices: newDashboard.ALL.invoices,
    franchises: data.franchises,
  });
  return { ...data, history, dashboard: newDashboard };
};

const createDashboardAllCountries = (dashboard, year) => {
  console.log("dashboard", dashboard);
  const arrCountriesInEur = Object.keys(dashboard)
    .filter((key) => key !== "all")
    .map((keyCountry) => ({
      ...convertcountryToEur(
        dashboard[keyCountry],
        countryCurrency[keyCountry] || 1
      ),
    }));
  const editedDashboard = _.cloneDeep(dashboard);

  // console.log("edited dashboard", editedDashboard);
  Object.keys(editedDashboard)
    .filter((key) => key !== "all")
    .forEach((keyCountry) => {
      editedDashboard[keyCountry] = {
        ...editedDashboard[keyCountry],
        gmv: convertValueToEur(
          editedDashboard[keyCountry].gmv,
          countryCurrency[keyCountry]
        ),
        [`gmv_${year}`]: convertValueToEur(
          editedDashboard[keyCountry].gmv,
          countryCurrency[keyCountry]
        ),
        invoices: (editedDashboard[keyCountry].invoices = [
          ...createDateCategoryInInvoices(
            editedDashboard[keyCountry].invoices
          ).map((invoice) =>
            convertInvoiceToEur(invoice, countryCurrency[keyCountry] || 1)
          ),
        ]),
      };
    });
  // console.log("edited dashboard final", editedDashboard);
  const allCountries = arrCountriesInEur.reduce(
    (prevValue, currentValue) => {
      // console.log("valor current", currentValue);
      return {
        ...prevValue,
        [`gmv_${year}`]: prevValue.gmv + currentValue.gmv,
        gmv: prevValue.gmv + currentValue.gmv,
        last_month: prevValue.last_month + currentValue.last_month,
        month: prevValue.month + currentValue.month,
        total: prevValue.total + currentValue.total,
        invoices: [
          ...prevValue.invoices.map(transformInvoiceToTimeZone),
          ...createDateCategoryInInvoices(currentValue.invoices),
        ],
      };
    },
    { gmv: 0, invoices: [], last_month: 0, month: 0, total: 0 }
  );

  // console.log("test dashboard", { ...editedDashboard, ALL: allCountries });
  return { ...editedDashboard, ALL: allCountries };
};

const createDateCategoryInInvoices = (invoices) => {
  return (
    invoices?.map((invoice) => {
      return transformInvoiceToTimeZone({
        ...invoice,
        dateCategory: `${invoice.date.substring(0, 4)}_${invoice.category}`,
      });
    }) || []
  );
};

const transformInvoiceToTimeZone = (invoice) =>
  invoice.date.substring(0, 4) >= "2024"
    ? {
        ...invoice,
        date: momentTz(invoice.date).tz("Europe/Madrid").format("YYYY-MM-DD"),
        created_at: momentTz(invoice.created_at)
          .tz("Europe/Madrid")
          .format("YYYY-MM-DD"),
        updated_at: momentTz(invoice.updated_at)
          .tz("Europe/Madrid")
          .format("YYYY-MM-DD"),
      }
    : invoice;

const convertcountryToEur = (country, currency) => {
  console.log("country convert to eur", country, currency);
  const countryObj = {
    gmv: country.gmv ? country.gmv * currency : 0,
    last_month: country.last_month * currency,
    month: country.month * currency,
    total: country.total * currency || 0,
  };

  if (country.invoices) {
    console.log("prev", country.invoices, currency);
    countryObj.invoices = [
      ...country.invoices.map((invoice) => ({
        ...convertInvoiceToEur(invoice, currency),
      })),
    ];
    console.log("finish", countryObj.invoices, currency);
  } else {
    countryObj.invoices = [];
  }

  console.log("country converted to eur", countryObj);
  return countryObj;
};

const convertValueToEur = (value, currency) => value * currency;

const createHistory = (data) => {
  const { invoices, franchises } = data;

  const dictFranchises = createDictFranchises(franchises);
  return createHistoryByProperty(invoices, dictFranchises);
};

const createDictFranchises = (franchises) => {
  const dictFranchises = {};
  franchises.forEach((franchise) => {
    const dictClients = {};

    franchise.clients.forEach((client) => {
      dictClients[client._id] = client.name;
    });

    dictFranchises[franchise._id] = {
      name: franchise.name,
      country: franchise.country,
      clients: dictClients,
    };
  });

  return dictFranchises;
};

const createHistoryByProperty = (invoices, dictFranchises) => {
  const historyInvoices = [];

  invoices.forEach((invoice) => {
    const restInvoice = {
      ...invoice,
      subtotal: numeral(invoice.subtotal).format("0,0[.]00$"),
      tax: numeral(invoice.tax).format("0,0[.]00$"),
      total: numeral(invoice.subtotal + invoice.tax).format("0,0[.]00$"),
      clientName:
        dictFranchises[invoice?.franchiseId]?.clients[invoice?.clientId],
      franchiseName: dictFranchises[invoice.franchiseId]?.name,
      country: dictFranchises[invoice.franchiseId]?.country,
    };

    historyInvoices.push({
      ...restInvoice,
      history_date: invoice.created_at,
    });
  });

  return historyInvoices.sort((a, b) =>
    a.history_date < b.history_date
      ? 1
      : a.history_date > b.history_date
      ? -1
      : 0
  );
};
