import React, { useContext } from "react";
import UserContext from "providers/UserProvider";
import TopBar from "components/TopBar/TopBar";
import Content from "components/Content/Content";

import Grid from "@material-ui/core/Grid";

import { SideDrawer } from "react-library-sm";

const Home = (props) => {
  const userContext = useContext(UserContext);

  const toggleDrawer = () => {
    props.menuHandler(!userContext.menuOpen);
  };
  return (
    <div>
      <TopBar
        languageChanged={props.languageChanged}
        style={{ height: "40px" }}
        toggleDrawer={toggleDrawer}
        open={userContext.menuOpen}
      />
      <Grid container spacing={0}>
        <Grid item md={1}>
          <SideDrawer
            openMenu={userContext.menuOpen}
            variant="persistent"
            toggleDrawer={toggleDrawer}
            data={userContext.translation.sideDrawer}
          />
        </Grid>
        <Grid item md={12}>
          <Content open={userContext.menuOpen} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Home;
