import React, { Component } from "react";
import languages from "assets/languages/languages";
import UserContext from "providers/UserProvider";
import Login from "containers/Login/Login";
import ResetPassword from "containers/ResetPassword";
import theme from "core/MUITheme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { Switch, Route } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import Home from "./components/Home";
import { Auth } from "aws-amplify";
import LogoSM from "assets/img/white-logo-lg.png";

class App extends Component {
  constructor(props) {
    super();
    Auth.currentSession()
      .then((result) => {
        if (result.getAccessToken().getJwtToken()) {
          this.setState({ statusUser: "loggedIn" });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ statusUser: "notLoggedIn" });
      });
    this.languageDefault = languages.find((lang) => {
      return lang.value === "es";
    });
  }

  state = {
    languageItem: languages.find((lang) => {
      return lang.value === navigator.language.substring(0, 2);
    }),
    statusUser: "loading",
    userCognito: null,
    menuOpen: true,
  };

  menuHandler = (value) => {
    this.setState({ menuOpen: value });
  };

  languageChangeHandler = (value) => {
    let language = languages.find((lang) => {
      return lang.value === value;
    });

    this.setState({
      languageItem: language ? language : this.languageDefault,
    });
  };

  userLoginSuccessHandler = (userCognito) => {
    this.setState({
      statusUser: "loggedIn",
      userCognito: userCognito,
    });
  };

  resetPassworSuccessdHandler = () => {
    this.setState({ statusUser: "loggedIn" });
  };
  render() {
    let languageView = this.state.languageItem
      ? this.state.languageItem
      : this.languageDefault;

    let content;
    switch (this.state.statusUser) {
      case "loading":
        content = (
          <div
            style={{
              height: "100vh",
              background: "#000",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={LogoSM} style={{ width: "200px" }} alt="logo SM<" />
          </div>
        );
        break;
      case "notLoggedIn":
        content = (
          <Switch>
            <Route
              path="/"
              exact
              render={() => (
                <Login
                  login={this.login}
                  loginSuccess={this.userLoginSuccessHandler}
                />
              )}
            />
            <Route
              path="/password-reset"
              exact
              render={() => (
                <ResetPassword
                  resetPasswordSuccess={this.resetPassworSuccessdHandler}
                />
              )}
            />
          </Switch>
        );
        break;
      case "loggedIn":
        content = (
          <Home
            languageChanged={this.languageChangeHandler}
            menuHandler={this.menuHandler}
          />
        );
        break;
      default:
        alert("Error, estado no definido");
        this.setState({ statusUser: "notLoggedIn" });
        break;
    }

    // console.log(this.state.statusUser);

    return (
      <MuiThemeProvider theme={theme}>
        <UserContext.Provider
          value={{
            language: languageView,
            translation: require(`${languageView.translation}`).language,
            userCognito: this.state.userCognito,
            menuOpen: this.state.menuOpen,
          }}
        >
          <CssBaseline />
          {content}{" "}
        </UserContext.Provider>{" "}
      </MuiThemeProvider>
    );
  }
}

export default App;
