import React, { Component } from "react";
import UserContext from "providers/UserProvider";
import { AcademyCoursesList, Grid } from "react-library-sm";
import AcademyServices from "services/central-academy/academy.services";
import { Redirect } from "react-router-dom";

class AcademyCoursesListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courses: null,
      alertAPI: false,
      alert: null,
    };
    this.academyService = new AcademyServices();
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  componentDidMount() {
    this.academyService
      .getCourses()
      .then((response) => {
        // console.log("response", response.data);
        // IMPROVE
        let courses = response.data.map((course) => {
          let languages = [];
          course.keys.forEach((key) => {
            languages.push({ language: key.code, status: key.status });
          });
          let dataLanguage = { ...course.languages };
          return {
            ...course,
            name: dataLanguage.name,
            description: dataLanguage.description,
            languages: languages,
          };
        });
        // console.log("final courses", courses);
        this.setState({ courses });
      })
      .catch((err) => console.log(err));
  }

  handleAddCourse = () => {
    this.academyService
      .addCourse({
        status: "building",
        category: "tech",
        tags: [],
        languages: [
          {
            name: "Curso nuevo",
            code: "es",
            description: "",
            blocks: {},
            status: "draft",
            reviewed: false,
          },
        ],
      })
      .then((response) => {
        this.setState({ newCourse: response.data._id });
      });
  };

  render() {
    // let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    let redirect = this.state.newCourse ? (
      <Redirect to={`/academy/courses/${this.state.newCourse}`} />
    ) : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {redirect}
        {alert}
        <AcademyCoursesList
          courses={this.state.courses}
          handleAddCourse={this.handleAddCourse}
          variant="central"
        />
      </Grid>
    );
  }
}

export default AcademyCoursesListComponent;
