import Axios from "core/axios";

class AcademyServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_PROJECTS;
    this.service = new Axios(this.api_url);
  }

  addTemplate = content => this.service.post("/templates", content);

  getTemplates = () => this.service.get("/templates");

  getTemplateLanguage = (id, language) =>
    this.service.get(`/templates/${id}?lang=${language}`);

  editTemplateType = (id, type) =>
    this.service.put(`/templates/${id}/type`, { type: type });

  reviewLanguage = (id, language, reviewed) =>
    this.service.put(`/templates/${id}/review?lang=${language}`, {
      reviewed: reviewed
    });

  editLanguageInfo = (id, language, content) =>
    this.service.put(`/templates/${id}/info?lang=${language}`, content);

  editTemplateStatus = (id, status) =>
    this.service.put(`/templates/${id}/status`, { status: status });

  addLanguage = (id, language) =>
    this.service.post(`/templates/${id}/lang`, language);

  deleteTemplate = id => this.service.delete(`/templates/${id}`);

  deleteLanguage = (id, language) =>
    this.service.delete(`/templates/${id}/lang?lang=${language}`);

  editLanguageTags = (id, language, content) =>
    this.service.put(`/templates/${id}/tags?lang=${language}`, content);

  editLanguageStatus = (id, language, status) =>
    this.service.put(`/templates/${id}/lang/status?lang=${language}`, {
      status: status
    });

  getLanguageTask = (id, language, task) =>
    this.service.get(`/templates/${id}/tasks/${task}?lang=${language}`);

  putLanguageTask = (id, language, task, content) =>
    this.service.put(
      `/templates/${id}/tasks/${task}?lang=${language}`,
      content
    );
  postLanguageTask = (id, language, content) =>
    this.service.post(`/templates/${id}/tasks?lang=${language}`, content);

  deleteLanguageTask = (id, language, task, content) =>
    this.service.delete(
      `/templates/${id}/tasks/${task}?lang=${language}`,
      content
    );

  editLanguageDetails = (id, language, details) =>
    this.service.put(`/templates/${id}/details?lang=${language}`, {
      details: details
    });

  editTemplateBPractice = (id, bPractice) =>
    this.service.put(`/templates/${id}/practice`, {
      isBestPractice: bPractice
    });

  getFullTemplateLanguage = (id, language) =>
    this.service.get(`/templates/${id}/full?lang=${language}`);

  restartTemplate = id => this.service.put(`/templates/${id}/restart`);
}

export default AcademyServices;
