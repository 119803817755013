import Spanish from "./icons/icon-spain.png";
import English from "./icons/icon-usa.png";
import French from "./icons/icon-france.png";

const languages = [
  {
    value: "es",
    text: "ESPAÑOL",
    valueImage: Spanish,
    translation: "./assets/languages/i18n/es.js"
  },
  {
    value: "en",
    text: "ENGLISH",
    valueImage: English,
    translation: "./assets/languages/i18n/en.js"
  },
  {
    value: "fr",
    text: "FRANÇAIS",
    valueImage: French,
    translation: "./assets/languages/i18n/fr.js"
  }
];

export default languages;
