import React, { Component } from "react";
import UserServices from "services/users.services.js";
import UserContext from "providers/UserProvider";
import { CentralUsersCentral as UsersCentral, Grid } from "react-library-sm";

class FranchisesComponent extends Component {
  constructor(props) {
    super(props);
    this.service = new UserServices();
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    this.service
      .getUsers()
      .then((response) => {
        this.setState({ users: [...response.data] });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  static contextType = UserContext;

  createUserCentral = (values) => {
    // console.log("values users", values);
    values.users.forEach((user) => {
      this.service
        .postUser(user)
        .then((response) => {
          let users = this.state.users;
          users.push(user);
          // console.log(users);
          this.setState({ users });
        })
        .catch((err) => {
          // console.log("error add user", err);
        });
    });
  };

  render() {
    // console.log("render calling", this.state.users);
    let content = (
      <UsersCentral
        columns={this.context.translation.dataTables.users.columns}
        title={this.context.translation.dataTables.users.title}
        options={{
          textLabels: this.context.translation.dataTables.textLabels,
        }}
        data={this.state.users}
        createUserCentral={this.createUserCentral}
        language={this.context.language}
      />
    );
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Grid item md={12} key={this.state.users.length}>
          {content}
        </Grid>
      </Grid>
    );
  }
}

export default FranchisesComponent;
