import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Templates from "containers/Templates";
import Template from "containers/Template";
import { default as Opportunity } from "containers/Franchises/Opportunity";
import FranchiseList from "containers/Franchises/List";
import UsersCentral from "containers/Users/Users";
import FranchiseSingle from "containers/Franchises/Single";
import InvoiceSingle from "containers/Billing/Single";
import InvoiceList from "containers/Billing/List";
import BillingSettings from "containers/Billing/Settings";
import SubscriptionList from "containers/Billing/Subscriptions/List";
import SubscriptionSingle from "containers/Billing/Subscriptions/Single";
import OfficeList from "containers/Billing/Office/List";
import ProjectContainer from "containers/Franchises/Project";
import AcademyCoursesList from "containers/Academy/Courses/List";
import AcademyCoursesSingle from "containers/Academy/Courses/Single";
import AcademyCoursesPreview from "containers/Academy/Courses/Preview";

import { Switch, Route } from "react-router-dom";

const drawerWidth = 240;
const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.background.default,
    height: "100vh"
  },
  contentShift: {
    // paddingLeft: theme.spacing(9),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: drawerWidth
  },
  toolbar: theme.mixins.toolbar
}));

const Content = ({ open }) => {
  const classes = useStyles();

  return (
    <main
      className={clsx(classes.content, {
        [classes.contentShift]: open
      })}
    >
      <div className={classes.toolbar} />
      <Switch>
        <Route path="/" exact component={FranchiseList} />
        <Route path="/franchises" exact component={FranchiseList} />
        <Route path="/franchises/:id" exact component={FranchiseSingle} />
        <Route path="/users" exact component={UsersCentral} />
        <Route path="/templates" exact component={Templates} />
        <Route
          path="/add-template"
          exact
          render={() => {
            return <Template variant="add" />;
          }}
        />
        <Route path="/templates/:id" exact component={Template} />
        <Route
          path="/franchises/:franchiseid/pipeline/:id/view"
          exact
          component={Opportunity}
        />
        <Route
          path="/franchises/:franchiseid/project/:id/view"
          exact
          component={ProjectContainer}
        />
        <Route
          path="/add-invoice"
          exact
          render={() => <InvoiceSingle variant="add" />}
        />
        <Route path="/billing-settings" exact component={BillingSettings} />
        <Route path="/invoices" exact component={InvoiceList} />
        <Route
          path="/invoices/:id/:action"
          exact
          render={params => (
            <InvoiceSingle
              variant={params.match.params.action}
              id={params.match.params.id}
              drawerOpen={open}
            />
          )}
        />
        <Route path="/subscriptions" exact component={SubscriptionList} />
        <Route path="/subscriptions/:id" exact component={SubscriptionSingle} />
        <Route path="/office" exact component={OfficeList} />
        <Route path="/academy/courses" exact component={AcademyCoursesList} />
        <Route
          path="/academy/courses/:id"
          exact
          component={AcademyCoursesSingle}
        />
        <Route
          path="/academy/courses/:id/:language/preview"
          exact
          component={AcademyCoursesPreview}
        />
      </Switch>
    </main>
  );
};

export default Content;
