import React, { Component } from "react";
import SubscriptionServices from "services/central-billing/subscriptions.services";
import FranchiseServices from "services/franchises.services";
import UserContext from "providers/UserProvider";
import moment from "moment";
import { Subscriptions, Grid, setLocale } from "react-library-sm";

// const response = {
//   data: {
//     subscriptions: [
//       {
//         _id: "sub_9h6CopvY0Fldnj",
//         stripe: true,
//         country: "ES",
//         billing_cycle_anchor: 1481027076,
//         collection_method: "charge_automatically",
//         customer: "Sevilla",
//         ended_at: 1481027085,
//         items: [
//           {
//             id: "si_19Nhzs2eZvKYlo2CVCDaXmrl",
//             plan: {
//               _id: "plan_19Nhzs2eZvKYlo2CVCDaXmrl",
//               value: "monthly",
//               label: "Monthly",
//               amount: 5000,
//               interval_count: 1,
//               product: "prod_BTftx04xthAjuR"
//             },
//             product: {
//               value: "prod_BTftx04xthAjuR",
//               label: "Sportmadness ESP"
//             }
//           }
//         ],
//         next_invoice: "2020-01-22T13:07:00.000Z",
//         last_invoice: 430,
//         quantity: 1,
//         schedule: null,
//         start_date: "2018-08-22T13:07:00.000Z",
//         status: "paused"
//       },
//       {
//         _id: "sub_9h6CopvY0Fldnj",
//         stripe: false,
//         country: "MX",
//         fixed: 299,
//         variable: 1.5,
//         interval_count: 1,
//         billing_cycle_anchor: "2019-08-22T13:07:00.000Z",
//         collection_method: "charge_automatically",
//         customer: "Barcelona Oeste",
//         ended_at: 1481027085,
//         items: [
//           {
//             id: "si_19Nhzs2eZvKYlo2CVCDaXmrl",
//             plan: {
//               _id: "plan_19Nhzs2eZvKYlo2CVCDaXmrl",
//               value: "monthly",
//               label: "Monthly",
//               amount: 5000,
//               interval_count: 1,
//               product: "prod_BTftx04xthAjuR"
//             },
//             product: {
//               value: "prod_BTftx04xthAjuR",
//               label: "Sportmadness ESP"
//             }
//           }
//         ],
//         next_invoice: "2019-09-22T13:07:00.000Z",
//         last_invoice: 582,
//         quantity: 1,
//         schedule: null,
//         start_date: "2019-08-22T13:07:00.000Z",
//         status: "active"
//       }
//     ],
//     dashboard: {
//       ES: {
//         month: 5033,
//         next_month: 3500,
//         last_month: 2500,
//         total: 40200
//       },
//       MX: {
//         month: 2033,
//         next_month: 1500,
//         last_month: 3500,
//         total: 23200
//       },
//       all: {
//         month: 7033,
//         next_month: 5000,
//         last_month: 3000,
//         total: 63400
//       }
//     }
//   }
// };
class SubscriptionsListComponent extends Component {
  constructor(props) {
    super(props);
    this.subscriptionService = new SubscriptionServices();
    this.franchiseService = new FranchiseServices();
    this.state = {
      subscriptions: [],
      dashboard: {},
      franchises: [],
      plans: [],
      products: [],
      year: moment().format("YYYY"),
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    setLocale("ES");
    this.callApi();
  }

  callApi = () => {
    this.subscriptionService
      .getSubscriptions(this.state.year)
      .then((response) => {
        // console.log("response getSubscriptions", response);

        this.subscriptionService.getSubscriptionInfo().then((resp) => {
          // console.log("response getSubscriptionInfo", resp);
          this.setState({
            subscriptions: response.data.subscriptions,
            dashboard: response.data.dashboard,
            franchises: resp.data.franchises,
            plans: resp.data.plans,
            products: resp.data.products,
          });
        });
      });
  };

  handleChangeYear = (year) => {
    this.setState({ year }, () => {
      this.callApi();
    });
  };

  createSubscription = (values) => {
    // console.log("createSubscription values", JSON.stringify(values));
    this.subscriptionService
      .postSubscription(values)
      .then((resp) => {
        // console.log("response postSubscription", resp);
        if (resp.status === 200) {
          this.subscriptionService.getSubscriptions().then((response) => {
            this.setState({
              ...this.state,
              subscriptions: response.data.subscriptions,
              dashboard: response.data.dashboard,
            });
          });
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <Subscriptions
          createSubscription={this.createSubscription}
          data={this.state.subscriptions}
          dashboard={this.state.dashboard}
          plans={this.state.plans}
          year={this.state.year}
          handleChangeYear={this.handleChangeYear}
          products={this.state.products}
          clients={this.state.franchises}
          columns={this.context.translation.dataTables.subscriptions.columns}
          title={this.context.translation.dataTables.subscriptions.title}
        />
      </Grid>
    );
  }
}

export default SubscriptionsListComponent;
