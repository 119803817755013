import Axios from "core/axios";

class FranchiseServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_FRANCHISES;
    this.service = new Axios(this.api_url);
  }

  getFranchises = () => {
    // console.log("entro getFranchises");
    return this.service.get("/franchises",);
  };

  getFranchisesDashboardByYear = (year) => {

    return this.service.get(`/franchisesbyyear/${year}`,);
  };

  postFranchise = data => {
    return this.service.post("/franchises", data);
  };

  getFranchise = id => {
    // console.log('entro getFranchise', id);
    return this.service.get(`/franchises/${id}`);
  };

  putFranchise = data => {
    console.log("data", data);
    return this.service.put(`/franchises/${data._id}`, data);
  };

  disableFranchise = data => {
    return this.service.put(`/franchises/${data._id}/delete`, data);
  };

  getUsersFranchise = () => {
    return this.service.get("/usersfranchise");
  };
}

export default FranchiseServices;
