export const MXN = 0.051;
export const SOL = 0.14;
export const EUR = 1;
export const CZK = 0.04;
export const BRL = 0.19;
export const URU = 0.02;
export const USD = 0.93;
export const FRS = 1.04;
export const CLP = 0.00097;
export const COP = 0.00025;
export const KWD = 3.04;

export const countryCurrency = {
  BO: SOL,
  MX: MXN,
  ES: EUR,
  CZ: CZK,
  BR: BRL,
  UY: USD,
  PA: USD,
  CH: FRS,
  IT: EUR,
  FR: EUR,
  CL: CLP,
  CO: COP,
  KW: KWD,
};
