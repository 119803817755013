import Axios from "core/axios";

class SettingsServices {
    constructor() {
        this.api_url = process.env.REACT_APP_API_CENTRAL_BILLING;
        this.service = new Axios(this.api_url);
    }

    getSettings = () => this.service.get("/settings?centralId=5df776376aa15623df72a6b7");

    /* Payment methods */
    postPaymentMethod = data => this.service.post("/settings/payment?centralId=5df776376aa15623df72a6b7", data);
    putPaymentMethod = data => this.service.put(`/settings/payment/?centralId=5df776376aa15623df72a6b7`, data);
    deletePaymentMethod = id => this.service.delete(`/settings/payment/${id}?centralId=5df776376aa15623df72a6b7`)

    /* Series  */
    postSerie = data => this.service.post("/settings/series?centralId=5df776376aa15623df72a6b7", data);
    editSerie = data => this.service.put(`/settings/series?centralId=5df776376aa15623df72a6b7`, data);
    deleteSerie = id => this.service.delete(`/settings/series/${id}?centralId=5df776376aa15623df72a6b7`)

    /* Info  */
    editInfo = data => this.service.put(`/settings/info?centralId=5df776376aa15623df72a6b7`, data);

}

export default SettingsServices;