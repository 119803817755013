import Axios from "core/axios";

class AcademyServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_ACADEMY;
    this.service = new Axios(this.api_url);
  }

  getCourses = () => this.service.get("/courses?lang=es");

  getCourse = id => this.service.get(`/courses/${id}?lang=es`);

  getCourseLanguage = (id, language) =>
    this.service.get(`/courses/${id}?lang=${language}`);

  saveLanguageContent = (id, language, content) =>
    this.service.put(`/courses/${id}/content?lang=${language}`, content);

  reviewLanguage = (id, language, reviewed) =>
    this.service.put(`/courses/${id}/review?lang=${language}`, {
      reviewed: reviewed
    });

  editLanguageInfo = (id, language, content) =>
    this.service.put(`/courses/${id}/info?lang=${language}`, content);
  editCourseStatus = (id, status) =>
    this.service.put(`/courses/${id}/status`, { status: status });

  addLanguage = (id, language) =>
    this.service.post(`/courses/${id}/lang`, language);

  deleteCourse = id => this.service.delete(`/courses/${id}`);
  deleteLanguage = (id, language) =>
    this.service.delete(`/courses/${id}/lang?lang=${language}`);

  addCourse = data => this.service.post("/courses", data);

  editLangaugeStatus = (id, language, content) =>
    this.service.put(`/courses/${id}/lang/status?lang=${language}`, content);

  editTags = (id, content) =>
    this.service.put(`/courses/${id}/tags?lang=es`, content);
  editCategory = (id, content) =>
    this.service.put(`/courses/${id}/category?lang=es`, content);

  restartCourse = id => this.service.delete(`/courses/${id}/restart`);
}

export default AcademyServices;
