import axios from "axios";
import { Auth } from "aws-amplify";

class axiosClient {
  constructor(API_SERVICE_URL) {
    this.client = axios.create({
      baseURL: API_SERVICE_URL
    });
  }

  get = url => {
    return Auth.currentSession().then(response => {
      return this.client.get(url, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          "Content-Type": "application/json"
        }
      });
    });
  };

  post = (url, data) => {
    return Auth.currentSession().then(response => {
      return this.client.post(url, data, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          "Content-Type": "application/json"
        }
      });
    });
  };

  put = (url, data) => {
    return Auth.currentSession().then(response => {
      return this.client.put(url, data, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          "Content-Type": "application/json"
        }
      });
    });
  };

  delete = url => {
    return Auth.currentSession().then(response => {
      return this.client.delete(url, {
        headers: {
          Authorization: response.getIdToken().getJwtToken(),
          "Content-Type": "application/json"
        }
      });
    });
  };
}

export default axiosClient;
