import Axios from "core/axios";

class OfficeServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_BILLING;
    this.service = new Axios(this.api_url);
  }

  getOfficeSubscriptions = () =>
    this.service.get("/office?centralId=5df776376aa15623df72a6b7");
  getSubscriptionInfo = () => this.service.get("/subscriptions/info");
  getSubscription = id =>
    this.service.get(`/office/${id}?centralId=5df776376aa15623df72a6b7`);
  postOfficeSubscription = data =>
    this.service.post("/office?centralId=5df776376aa15623df72a6b7", data);
}

export default OfficeServices;
