import React, { Component } from "react";
import { FranchiseOpportunitySingle as OpportunitySingle } from "react-library-sm";
import { Grid } from "@material-ui/core";
import OpportunitiesServices from "services/franchise-crm/opportunities.services";

import UserContext from "providers/UserProvider";

import { Redirect } from "react-router-dom";

class Opportunity extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opportunity: {},
      deletedOpportunity: false,
    };
    this.opportunityService = new OpportunitiesServices();
    this.opportunityId = null;
  }

  static contextType = UserContext;

  componentDidMount() {
    this.opportunityId = this.props.match.params.id;
    this.getOpportunity();
  }

  getOpportunity = () => {
    this.opportunityService
      .getOpportunity(
        this.props.match.params.id,
        this.props.match.params.franchiseid
      )
      .then((response) => {
        // console.log("response0", response);
        const opportunity = { ...response.data[0] };
        opportunity.contact = opportunity.client[0];

        opportunity.statusLabel =
          this.context.translation.statusSells[opportunity.statusSells];
        opportunity.serviceTypeLabel =
          this.context.translation.service_type[opportunity.type];

        const tasks = opportunity.tasks.map((task, index) => {
          // console.log('task' + index, task);
          return {
            ...task,
            person_of_contact: task.contact.name ? task.contact.name : "",
          };
        });

        opportunity.contact.people = opportunity.contact.people
          .filter((person) => person.visible)
          .map((person) => {
            return { ...person, value: person._id, label: person.name };
          });
        this.setState({ opportunity, tasks });
      })
      .catch((err) => {
        // console.log("err get project", err);
      });
  };

  editOpportunity = (data) => {
    // console.log("editOpportunity data", data);
    const editOpportunity = { ...data };
    const tasks = [...this.state.tasks];
    editOpportunity.client = editOpportunity.client.map((client) => client._id);
    editOpportunity.tasks = tasks;
    this.opportunityService
      .updateOpportunity(editOpportunity, this.context.franchise)
      .then((response) => {
        // console.log("updateOpp response", response);
        const opportunity = { ...response.data };
        opportunity.contact = opportunity.client && opportunity.client[0];
        // console.log("opportunity.contact", opportunity);
        opportunity.statusLabel =
          this.context.translation.statusSells[opportunity.statusSells];
        opportunity.serviceTypeLabel =
          this.context.translation.service_type[opportunity.type];

        const tasks =
          opportunity.tasks &&
          opportunity.tasks.map((task, index) => {
            // console.log('task' + index, task);
            return {
              ...task,
              person_of_contact: task.contact.name ? task.contact.name : "",
            };
          });

        opportunity.contact.people = opportunity.contact.people
          .filter((person) => person.visible)
          .map((person) => {
            return { ...person, value: person._id, label: person.name };
          });
        this.setState({ opportunity, tasks });
      })
      .catch((err) => {
        // console.log("err update opp", err);
      });
  };

  deleteOpportunity = () => {
    this.opportunityService
      .deleteOpportunity(this.props.match.params.id, this.context.franchise)
      .then((response) => {
        // console.log("delete board respn", response);
        this.setState({ deletedOpportunity: true });
      })
      .catch((err) => {
        // console.log("err delete project", err);
      });
  };

  createTask = (data) => {
    // OPTIMIZAR FORM DIALOG TASK
    const task = {
      ...data,
      contact: data.person_of_contact,
    };
    // console.log(data)
    this.taskService
      .addTask(this.props.match.params.id, this.context.franchise, task)
      .then((response) => {
        // console.log("response add task", response);
        const tasks = [...this.state.tasks];

        const createdTask = response.data.tasks[response.data.tasks.length - 1];
        //console.log('createdTask', createdTask);
        tasks.push({
          ...createdTask,
          person_of_contact: createdTask.contact.name,
        });
        this.setState({ tasks });
      })
      .catch((err) => {
        // console.log("err add task", err.response);
      });
  };

  editTask = (data) => {
    const task = {
      ...data,
      contact: data.person_of_contact,
    };
    // console.log('task to update', task);
    this.taskService
      .editTask(this.props.match.params.id, this.context.franchise, task)
      .then((response) => {
        // console.log('response edit task', response.data.tasks);

        // OPTIMIZACION LETI
        let tasks = [...this.state.tasks];

        tasks.some((task, index) => {
          if (task._id === data._id) {
            // console.log(response.data.tasks.find((res) => res._id === task._id));
            // const updatedTask = response.data.tasks.find((res) => res._id === task._id);
            tasks[index] = {
              ...response.data.tasks.find((res) => res._id === task._id),
              person_of_contact: this.state.opportunity.contact.people.find(
                (person) => person._id === data.person_of_contact
              ).name,
            };
            return true;
          } else return false;
        });

        this.setState({ tasks });
      })
      .catch((err) => {
        // console.log("err edit task", err.response);
      });
  };

  deleteTask = (data) => {
    this.taskService
      .deleteTask(this.props.match.params.id, this.context.franchise, data._id)
      .then((response) => {
        if (response.status === 201) {
          let tasks = [...this.state.tasks];
          tasks = tasks.filter((task) => task._id !== data._id);
          this.setState({ tasks });
        }
      })
      .catch((err) => {
        // console.log("err delete task", err.response);
      });
  };

  render() {
    if (this.opportunityId !== this.props.match.params.id) {
      this.opportunityId = this.props.match.params.id;
      this.getOpportunity();
    }
    // console.log("id opportunity", this.props.match.params);

    let content = this.state.deletedOpportunity ? (
      <Redirect to={`/franchises/${this.props.match.params.franchiseid}`} />
    ) : (
      <Grid
        container
        style={{
          position: "fixed",
          width: this.context.menuOpen ? "80%" : "100%",
        }}
      >
        <OpportunitySingle
          taskColumns={this.context.translation.dataTables.tasks.columns}
          title={this.context.translation.dataTables.tasks.title}
          options={{
            textLabels: this.context.translation.dataTables.textLabels,
          }}
          opportunity={this.state.opportunity}
          createTask={this.createTask}
          editTask={this.editTask}
          deleteTask={this.deleteTask}
          tasks={this.state.tasks || []}
          editOpportunity={this.editOpportunity}
          deleteOpportunity={this.deleteOpportunity}
        />
      </Grid>
    );

    return content;
  }
}

export default Opportunity;
