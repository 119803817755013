import React, { Component } from "react";

import SubscritpionServices from "services/central-billing/subscriptions.services";
import InvoiceServices from "services/central-billing/invoices.services";
import UserContext from "providers/UserProvider";
import { SubscriptionSingle, Grid, Alert } from "react-library-sm";

import { Redirect } from "react-router-dom";

class SubscriptionSingleComponent extends Component {
  constructor(props) {
    super(props);
    this.subscritpionService = new SubscritpionServices();
    this.invoiceService = new InvoiceServices();
    this.state = {
      subscription: {},
      invoices: [],
      invoice: null,
      duplicated: false,
      invoiceDuplicated: null,
      invoicesToPDF: [],
      info: null,
      alertAPI: false,
      alert: null,
      issued: false,
      invoiceIssued: null,
    };
  }

  componentDidMount() {
    // console.log(this.props.match.params.id);
    this.subscritpionService
      .getSubscription(this.props.match.params.id)
      .then((response) => {
        // console.log("getSubscription response", response);
        this.setState({
          subscription: response.data,
          invoices: response.data.subscription_invoices,
          info: response.data.subscription,
        });
      });
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  issueNewInvoice = (invoice) => {
    const tax =
      this.state.subscription.country === "ES"
        ? { value: "iva_21", percentage: 21 }
        : { value: "no_iva", percentage: 0 };

    this.subscritpionService
      .issueNewInvoice({
        ...invoice,
        items: [
          {
            name: "Suscripción Sportmadness",
            qty: 1,
            price: invoice.amount,
            subtotal: invoice.amount,
            tax: tax.value,
            total: invoice.amount * (1 + tax.percentage / 100),
          },
        ],
        subtotal: invoice.amount,
        tax: invoice.amount * (tax.percentage / 100),
        total: invoice.amount * (1 + tax.percentage / 100),
        franchise: this.state.subscription._id,
      })
      .then((response) => {
        // console.log("issueNewInvoice response", response);
        this.setState({
          invoiceIssued: response.data._id,
          issued: true,
        });
        // this.setState({
        //   alertAPI: true,
        //   alert: {
        //     open: true,
        //     title: "Invoice issued",
        //     status: "success",
        //     text: `Invoice created ${response.data.docnumber}`,
        //     submit: "Confirm",
        //     onSubmit: this.handleOnCloseAlert
        //   }
        // });
      })
      .catch((err) => console.log(err));
  };

  getInvoicesPdf = (ids) => {
    Promise.all([
      ...ids.map((id) => this.invoiceService.getInvoicePdf(id)),
    ]).then((responses) => {
      const invoicesToPDF = [];
      responses.forEach((response) => {
        invoicesToPDF.push(response.data);
      });

      this.setState({ invoicesToPDF });
    });
  };

  getInvoicePdf = (id) => {
    this.invoiceService
      .getInvoicePdf(id)
      .then((response) => {
        // console.log("getInvoicePdf respoonse", response);
        this.setState({ invoice_pdf: response.data });
      })
      .catch((err) => console.log(err));
  };

  editStatus = (values) => {
    // console.log("editStatus values", JSON.stringify(values));
    this.invoiceService
      .updateStatus(values)
      .then(
        (response) => response.status === 200 && this.setState({ edited: true })
      )
      .catch((err) => console.log(err));
  };

  sendNotification = (values) => {
    // console.log("sendNotifications values", JSON.stringify(values));
  };

  handleSendNotificationInvoices = (ids) => {
    // console.log("Invoices to send notification", ids);
    this.invoiceService
      .sendNotification(ids)
      .then((response) => {
        // console.log("sendNotification response", response);

        if (response.status === 200) {
          let invoices = [...this.state.invoices];
          let index, foundInvoice;
          if (ids.length === 1) {
            index = invoices.findIndex((invoice) => invoice._id === ids[0]);
            foundInvoice = response.data;
            invoices[index] = {
              ...foundInvoice,
              client: foundInvoice.franchise,
            };
          } else {
            ids.forEach((id) => {
              index = invoices.findIndex((invoice) => invoice._id === id);
              foundInvoice = response.data.find(
                (invoice) => invoice._id === id
              );
              invoices[index] = {
                ...foundInvoice,
                client: foundInvoice.franchise,
              };
            });
          }
          this.setState({ invoices });
        }
      })
      .catch((err) => console.log(err));
  };

  handleDuplicateInvoice = (id) => {
    this.invoiceService.duplicateInvoice(id).then((response) => {
      // console.log("duplicateInvoice response,", response);
      this.setState({
        invoiceDuplicated: response.data._id,
        duplicated: true,
      });
    });
    // .catch(err => console.log(err));
  };

  handleAmendInvoice = (id) => {
    this.invoiceService
      .amendInvoice(id)
      .then((response) => {
        // console.log("amendInvoice response", response);
        let invoices = [...this.state.invoices];
        invoices.filter((invoice) => !invoice.id === id);
        invoices.push(response.data.invoices);
        this.setState({
          invoices,
        });
      })
      .catch((err) => console.log(err));
  };

  handleDeleteInvoice = (invoice) => {
    // console.log("invoice to delete", invoice);
    this.invoiceService
      .deleteInvoice(invoice._id, invoice.franchise._id)
      .then((response) => {
        // console.log("response delete invoice", response.data);
        let invoices = [...this.state.invoices];
        invoices = invoices.filter((_invoice) => _invoice._id !== invoice._id);
        this.setState({
          invoices,
        });
      })
      .catch((err) => console.log(err.response));
  };

  render() {
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {" "}
        {this.state.issued ? (
          <Redirect
            to={`/invoices/${
              this.state.invoiceIssued && this.state.invoiceIssued
            }/edit`}
          />
        ) : null}
        {alert}
        <Grid item md={12}>
          {this.state.subscription ? (
            <SubscriptionSingle
              subscription={this.state.subscription}
              info={this.state.info}
              columns={[
                this.context.translation.dataTables
                  .subscription_forecast_invoices.columns,
                this.context.translation.dataTables.subscription_invoices
                  .columns,
              ]}
              title={[
                this.context.translation.dataTables
                  .subscription_forecast_invoices.title,
                this.context.translation.dataTables.subscription_invoices.title,
              ]}
              issueNewInvoice={this.issueNewInvoice}
              invoices={this.state.invoices}
              getInvoicePdf={this.getInvoicePdf}
              invoice_pdf={this.state.invoice_pdf}
              editStatus={this.editStatus}
              sendNotification={this.sendNotification}
              handleDuplicateInvoice={this.handleDuplicateInvoice}
              handleDeleteInvoice={this.handleDeleteInvoice}
              handleAmendInvoice={this.handleAmendInvoice}
              handleSelectInvoicesToDownload={this.getInvoicesPdf}
              handleSelectInvoicesToSend={this.handleSendNotificationInvoices}
              invoicesToPDF={this.state.invoicesToPDF}
              // edit={this.editSubscription}
              // cancel={this.cancelSubscription}
            />
          ) : (
            <h1>Cargando...</h1>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default SubscriptionSingleComponent;
