import React from "react";
import ReactDOM from "react-dom";
import Amplify from "aws-amplify";
import App from "./App";
import config from "./config";
import { BrowserRouter as Router } from "react-router-dom";

// import registerServiceWorker from "./registerServiceWorker";
import "./index.css";

console.log("Actual Version", "4.0.0");
console.log("API auth", "v1_3_0 ");
console.log("API central-franchises", "v1_4_1");
console.log("API central-projects", "v1_2_0");
console.log("API central-billing", "v1_5_3");
console.log("API central-academy", "v1_0_0");
console.log("API franchise-crm", "v1_5_1");
console.log("API franchise-projects", "v1_3_0");
console.log("API franchise-billing", "v1_3_2");
console.log("Library", "v6.0.0");

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
//registerServiceWorker();
