import Axios from "core/axios";

class ContactsServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_FRANCHISE_CRM;
    this.service = new Axios(this.api_url);
  }

  getContacts = franchise => {
    return this.service.get("/contacts?franchise=" + franchise);
  };

  addPersonOfContact = data => {
    return this.service.post("/contacts", data);
  };

  addContact = data => {
    return this.service.post("/contacts", data);
  };

  updateContact = data => {
    return this.service.put(`/contacts/${data._id}`, data);
  };

  getContact = (id, franchise) => {
    return this.service.get("/contacts/" + id + "?franchise" + franchise);
  };

  deleteContact = id => {
    return this.service.delete("/contacts/" + id);
  };

  createPeople = (people, franchise, client) => {
    // console.log('people en createPeopleService', people);
    if (people.length === 1) {
      // console.log('people 1', people);
      const _people = {
        ...people[0],
        client: client._id,
        franchise
      };
      return this.addPersonOfContact(_people);
    } else {
      // console.log('people +de1', people);
      const _people = people.map(person => {
        return {
          ...person,
          client: client._id,
          franchise
        };
      });
      return Promise.all(
        _people.map(person => {
          return this.addPersonOfContact(person);
        })
      );
    }
  };

  createContact = (contact, franchise) => {
    const _contact = {
      ...contact,
      billingInfo: { name: contact.taxName },
      franchise
    };
    if (contact.people.length === 0) {
      return this.addContact(_contact);
    } else if (contact.people.length === 1) {
      const temppeople = _contact.people[0];
      return this.addContact({ ..._contact, people: [] }).then(response => {
        _contact._id = response.data._id;

        return this.addPersonOfContact({
          ...temppeople,
          client: response.data._id,
          franchise
        });
      });
    } else {
      return this.addContact({ ..._contact, people: [] }).then(response => {
        _contact._id = response.data._id;
        return Promise.all(
          _contact.people.map(person => {
            return this.addPersonOfContact({
              ...person,
              client: response.data._id,
              franchise
            });
          })
        ).then(x => {
          const temp = [];
          x.forEach(element => {
            temp.push(element.data);
          });
          temp.push(_contact);
          return temp;
        });
      });
    }
  };
}

export default ContactsServices;
