import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
// import MailIcon from '@material-ui/icons/Mail';
import MoreIcon from "@material-ui/icons/MoreVert";
import MenuIcon from "@material-ui/icons/Menu";
import Logo from "components/UI/Logo/Logo";
// import { ListItemText, ListItemIcon } from "@material-ui/core";
// import languages from "assets/languages/languages";
// import UserContext from "providers/UserProvider";
import { Auth } from "aws-amplify";

import clsx from "clsx";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    flexDirection: "row",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("xs")]: {
      display: "block",
    },
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: "none",
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    //	width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
}));

const TopBar = ({ toggleDrawer, open, ...props }) => {
  // const userContext = useContext(UserContext);
  const classes = useStyles();
  const [anchorProfile, setAnchorProfile] = React.useState(null);
  const [, setAnchorLanguage] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuProfileOpen = Boolean(anchorProfile);
  // const isMenuLanguageOpen = Boolean(anchorLanguage);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleMenuOpen(event) {
    if (event.currentTarget.id === "profileButton") {
      setAnchorProfile(event.currentTarget);
    } else if (event.currentTarget.id === "languageButton") {
      setAnchorLanguage(event.currentTarget);
    }
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorProfile(null);
    setAnchorLanguage(null);
    handleMobileMenuClose();
  }

  function logout() {
    Auth.signOut()
      .then((data) => {
        // console.log("d", data);
        window.location.href = "/";
      })
      .catch((err) => console.log("e", err));
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  // const handleLanguageChanged = (language) => {
  //   handleMenuClose();
  //   props.languageChanged(language);
  // };

  // const getLanguages = () => {
  //   const items = [];
  //   for (const language of languages) {
  //     if (userContext.language.value !== language.value) {
  //       items.push(
  //         <MenuItem
  //           onClick={() => handleLanguageChanged(language.value)}
  //           key={language.value}
  //         >
  //           <ListItemIcon>
  //             <img src={language.valueImage} alt={language.value} />
  //           </ListItemIcon>
  //           <ListItemText primary={language.text} />
  //         </MenuItem>
  //       );
  //     }
  //   }

  //   return items;
  // };

  const renderMenuProfile = (
    <Menu
      anchorEl={anchorProfile}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={isMenuProfileOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuClose}>
        <ListItemText primary="Sent mail" />
      </MenuItem> */}
      <MenuItem onClick={logout}>Sign out</MenuItem>
    </Menu>
  );

  // const renderMenuLanguages = (
  //   <Menu
  //     anchorEl={anchorLanguage}
  //     anchorOrigin={{ vertical: "top", horizontal: "right" }}
  //     transformOrigin={{ vertical: "top", horizontal: "right" }}
  //     open={isMenuLanguageOpen}
  //     onClose={handleMenuClose}
  //   >
  //     {getLanguages()}
  //   </Menu>
  // );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem onClick={handleMenuOpen}>
				<IconButton color="inherit">
					<Badge badgeContent={11} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton>
				<p>Notifications</p>
			</MenuItem> */}
      <MenuItem onClick={handleMenuOpen}>
        <IconButton color="inherit">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar className={classes.Toolbar}>
          <IconButton color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Logo />
          <div className={classes.sectionDesktop}>
            {/* <IconButton color="inherit" onClick={handleMenuOpen}>
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            {/* <img
              src={userContext.language.valueImage}
              onClick={handleMenuOpen}
              id="languageButton"
              alt={userContext.language.value}
            /> */}
            <IconButton
              edge="end"
              aria-owns={isMenuProfileOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={handleMenuOpen}
              color="inherit"
              id="profileButton"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenuProfile}
      {/* {renderMenuLanguages} */}
      {renderMobileMenu}
    </div>
  );
};

export default TopBar;
