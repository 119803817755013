import React, { Component } from "react";
import InvoiceServices from "services/central-billing/invoices.services";
import UserContext from "providers/UserProvider";
import { InvoiceList, Grid, setLocale, Alert } from "react-library-sm";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { createDashboard } from "utils/dashboard";

class InvoiceListComponent extends Component {
  constructor(props) {
    super(props);
    this.invoiceService = new InvoiceServices();
    this.state = {
      invoices: [],
      invoice_pdf: null,
      invoice: null,
      duplicated: false,
      invoiceDuplicated: null,
      invoicesToPDF: [],
      alertAPI: false,
      alert: null,
      compareInvoices: [],
      yearsDownloaded: ["2023"],
      compareYears: [],
      totals: {},
      year: moment().format("YYYY"),
    };
    setLocale("ES");
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  getFinalDate = (date) => {
    let momentDate = moment(date);
    let updatedMomentDate = moment(date, "YYYY-MM-DDTHH:mm:ss").add(1, "day");
    // let dateHasChanged = false;
    // console.log(momentDate.format("DD"));
    // console.log(
    //   momentDate.format("YYYY-MM-DD HH:mm:ss"),
    //   updatedMomentDate.format("YYYY-MM-DD HH:mm:ss")
    // );
    if (
      momentDate.format("DD") ===
        moment(momentDate.format("YYYY-MM-DD")).endOf("month").format("DD") &&
      momentDate.format("YYYY") >= "2023"
    ) {
      // console.log("cambio fecha", momentDate);
      return updatedMomentDate.format("YYYY-MM-DD HH:mm:ss");
    }

    return momentDate.format("YYYY-MM-DD HH:mm:ss");
    // if (dateHasChanged) {
    //   return momentDate.add(1, "d").format("YYYY-MM-DD HH:mm:ss");
    // }
    // console.log("fecha actual", momentDate.format("YYYY-MM-DD HH:mm:ss"), date);
  };
  callApi = () => {
    // console.log("llamada api");
    this.invoiceService
      .getInvoices(null, null, {
        from: moment(`${this.state.year}/01/01`).toDate(),
        to: moment(`${this.state.year}/12/31`).toDate(),
      })
      .then((response) => {
        const invoices = response.data.invoices.map((invoice) => {
          if (invoice.type === "in")
            return {
              ...invoice,
              date: this.getFinalDate(invoice.date),
              client: {
                ...invoice.franchise,
                billingInfo: {
                  ...invoice.billingInfo,
                  name: invoice.franchise.billingInfo.isLegalEntity
                    ? invoice.franchise.billingInfo.name
                    : `${invoice.franchise.billingInfo.name} ${invoice.franchise.billingInfo.firstSurname} ${invoice.franchise.billingInfo.lastSurname}`,
                },
              },
            };
          else return { ...invoice };
        });
        // console.log({
        //   dashboard: createDashboard(invoices),
        //   invoices: invoices,
        // });
        this.setState({ invoices, dashboard: createDashboard(invoices) });
      })
      .catch((err) => console.log(err));
  };
  componentDidMount() {
    this.callApi();
    this.handleDownloadCompareYear(this.state.year);
  }

  handleChangeYear = (year) => {
    this.setState({ year }, () => {
      this.callApi();
    });
  };

  getInvoicesPdf = (ids) => {
    Promise.all([...ids.map((id) => this.invoiceService.getInvoice(id))])
      .then((responses) => {
        const invoicesToPDF = [];
        responses.forEach((response) => {
          invoicesToPDF.push(response.data);
        });

        this.setState({ invoicesToPDF });
      })
      .catch((err) => console.log(err));
  };

  getInvoicePdf = (id) => {
    this.invoiceService
      .getInvoicePdf(id)
      .then((response) => {
        this.setState({ invoice_pdf: response.data });
      })
      .catch((err) => console.log(err));
  };
  editStatus = (values) => {
    // console.log("editStatus values", JSON.stringify(values));
    this.invoiceService
      .updateStatus(values)
      .then((response) => {
        if (response.status === 200) {
          console.log("editStatus response", response);
          let invoices = [...this.state.invoices];
          invoices.some((invoice, index) => {
            if (invoice._id === response.data._id) {
              console.log("editStatus id", invoice._id);
              console.log("editStatus index", index);
              invoices[index] = {
                ...response.data,
                client: response.data.franchise,
              };
              return true;
            } else return false;
          });
          this.setState({ invoices });
        }
      })
      .catch((err) => console.log(err));
  };

  sendNotification = (id) => {
    // console.log("sendNotifications values", id);
    this.invoiceService
      .sendNotification([id])
      .then((response) => {
        // console.log("sendNotification response", response);
        // console.log("response", response);
        if (response.status === 200) {
          let invoices = [...this.state.invoices];
          invoices.some((invoice, index) => {
            if (invoice._id === response.data._id) {
              invoices[index] = response.data;
              return true;
            } else return false;
          });
          this.setState({ invoices });
        }
      })
      .catch((err) => console.log(err));
    // console.log("sendNotifications values", JSON.stringify(values));
  };

  handleSendNotificationInvoices = (ids) => {
    // console.log("Invoices to send notification", ids);
    this.invoiceService
      .sendNotification(ids)
      .then((response) => {
        // console.log("sendNotification response", response);

        if (response.status === 200) {
          this.setState({
            alertAPI: true,
            alert: {
              open: true,
              title:
                "Notifications sent. Reload the page to see changes applied in the table",
              status: "success",
              submit: "Great!",
              onSubmit: this.handleOnCloseAlert,
            },
          });
          // let invoices = [...this.state.invoices];
          // let index, foundInvoice;
          // if (ids.length === 1) {
          //   index = invoices.findIndex(invoice => invoice._id === ids[0]);
          //   console.log("index", index);
          //   // foundInvoice = { ...ids[0] };
          //   // console.log("foundInvoice", foundInvoice);
          //   console.log("invoicespre[index]", invoices[index]);
          //   invoices[index] = {
          //     ...invoices[index],
          //     client: invoices[index].franchise,
          //     sent: true
          //   };
          //   } else {
          //     ids.forEach(id => {
          //       index = invoices.findIndex(invoice => invoice._id === id);
          //       foundInvoice = id;
          //       invoices[index] = {
          //         ...foundInvoice,
          //         client: foundInvoice.franchise,
          //         sent: true
          //       };
          //     });
          //   }
          //   this.setState({ invoices });
        }
      })
      .catch((err) => console.log(err));
  };

  handleDuplicateInvoice = (id) => {
    this.invoiceService
      .duplicateInvoice(id)
      .then((response) => {
        // console.log("duplicateInvoice response,", response);
        this.setState({
          invoiceDuplicated: response.data._id,
          duplicated: true,
        });
      })
      .catch((err) => console.log(err));
  };

  handleAmendInvoice = (id) => {
    this.invoiceService
      .amendInvoice(id)
      .then((response) => {
        // console.log("amendInvoice response", response);
        let invoices = [...this.state.invoices];

        // invoices.filter(invoice => !invoice.id === id);
        invoices = invoices.map((_invoice) => {
          if (_invoice._id === id) {
            return { ..._invoice, status: "amended" };
          } else {
            return { ..._invoice };
          }
        });

        invoices.unshift(
          response.data.type === "in"
            ? {
                ...response.data.invoice,
                client: response.data.invoice.franchise,
              }
            : { ...response.data.invoice }
        );
        this.setState({
          invoices,
          dashboard: createDashboard(invoices),
          alertAPI: true,
          alert: {
            open: true,
            title: "Invoice Amended",
            status: "success",
            submit: "Great!",
            onSubmit: this.handleOnCloseAlert,
          },
        });
      })
      .catch((err) => console.log(err));
  };

  handleDeleteInvoice = (invoice) => {
    // console.log("invoice to delete", invoice);
    this.invoiceService
      .deleteInvoice(invoice._id, invoice.franchise._id)
      .then((response) => {
        // console.log("response delete invoice", response.data);
        let invoices = [...this.state.invoices];
        invoices = invoices.filter((_invoice) => _invoice._id !== invoice._id);
        this.setState({
          invoices,
        });
      })
      .catch((err) => console.log(err.response));
  };

  // handleSendNotificationInvoices = ids => {
  //   console.log("Invoices to send notification", ids);
  // };

  handleDownloadCompareYear = (year) => {
    this.invoiceService
      .getInvoices(null, null, {
        from: moment(`${year}/01/01`).toDate(),
        to: moment(`${year}/12/31`).toDate(),
      })
      .then((response) => {
        let invoices = response.data.invoices;
        console.log("invoices from aaa", year, invoices);

        invoices = invoices.map((invoice) => ({
          ...invoice,
          date: this.getFinalDate(invoice.date),
          dateCategory: `${year}_${invoice.category}`,
        }));

        this.setState({
          compareInvoices: [...this.state.compareInvoices, ...invoices],
          totals: {
            ...this.state.totals,
            [`total_${year}`]: invoices.reduce(
              (final, inv) => inv.subtotal + final,
              0
            ),
          },
        });

        // const compareDashboard = _.cloneDeep(this.state.compareDashboard);

        // console.log("final", year, compareDashboard);
        // this.setState({ compareDashboard: compareDashboard });
        // this.setState({
        //   franchises: responseParsed.franchises,
        //   taxes: responseParsed.taxes,
        //   dashboard: { ...responseParsed.dashboard },
        // });
      })
      .catch((err) => {
        console.log("err get franchises", err);
      });
  };

  handleSelectYears = (years) => {
    // console.log("years to download", years);
    const stateYears = [...this.state.yearsDownloaded];
    const yearsToDownload = years.filter((year) => !stateYears.includes(year));

    console.log("handleSelectYears", years, stateYears, yearsToDownload);
    yearsToDownload.forEach((year) => {
      this.handleDownloadCompareYear(year);
      stateYears.push(year);
    });

    this.setState({ yearsDownloaded: stateYears, compareYears: years });
    // const downloadYears = years.filter((year) => {
    //   console.log(
    //     "year a comprobar",
    //     year,
    //     stateYears,
    //     !stateYears.includes(year)
    //   );
    //   return !stateYears.includes(year);
    // });
    // console.log("solo van a comprobar estos años", downloadYears);
    // downloadYears.forEach((year) => {
    //   console.log("year to check", year);
    //   this.handleDownloadCompareYear(year);
    // });
    // this.setState({ compareYears: years });
  };

  getFilteredInvoices = (categories, statuses, dates) => {
    // console.log(
    //   "categories",
    //   categories,
    //   "statuses",
    //   statuses,
    //   "dates",
    //   dates,
    //   "dashboard",
    //   this.state.dashboard
    // );
    if (
      categories.length === 0 &&
      statuses.length === 0 &&
      !dates.from &&
      !dates.to
    ) {
      return Promise.reject();
    }

    const filteredInvoices = this.state.invoices.filter((invoice) => {
      // console.log(
      //   "check de fecha",
      //   invoice.date,
      //   dates.from && dates.to
      //     ? moment(invoice.date).isBetween(moment(dates.from), moment(dates.to))
      //     : dates.from
      //     ? moment(invoice.date).isSameOrAfter(dates.from)
      //     : dates.to
      //     ? moment(invoice.date).isSameOrBefore(dates.to)
      //     : true
      // );
      const dateFrom = dates.from
        ? moment(dates.from).format("YYYY-MM-DD")
        : null;
      const invoiceDate = moment(invoice.date).format("YYYY-MM-DD");
      const dateTo = dates.to ? moment(dates.to).format("YYYY-MM-DD") : null;
      // console.log(dateFrom, dateTo, invoiceDate);
      // console.log(
      //   "isBetween",
      //   moment(invoiceDate).isBetween(dateFrom, dateTo, null, "[]")
      // );
      // console.log("isSameOrAfter", moment(invoiceDate).isSameOrAfter(dateFrom));

      // console.log("isSameOrBefore", moment(invoiceDate).isSameOrBefore(dateTo));

      // console.log(
      //   "final decision",
      //   dateFrom && dateTo
      //     ? moment(invoiceDate).isBetween(dateFrom, dateTo, null, "[]")
      //     : dateFrom
      //     ? moment(invoiceDate).isSameOrAfter(dateFrom)
      //     : dateTo
      //     ? moment(invoiceDate).isSameOrBefore(dateTo)
      //     : true
      // );

      return (
        (categories.length > 0
          ? categories.includes(invoice.category)
          : true) &&
        (statuses.length > 0 ? statuses.includes(invoice.status) : true) &&
        (dateFrom && dateTo
          ? moment(invoiceDate).isBetween(dateFrom, dateTo, null, "[]")
          : dateFrom
          ? moment(invoiceDate).isSameOrAfter(dateFrom)
          : dateTo
          ? moment(invoiceDate).isSameOrBefore(dateTo)
          : true)
      );
    });
    console.log(
      filteredInvoices,
      filteredInvoices.reduce((accumulator, invoice) => {
        return accumulator + invoice.subtotal;
      }, 0)
    );
    return Promise.resolve({
      data: {
        dashboard: createDashboard(filteredInvoices),
        invoices: filteredInvoices,
      },
    });
  };

  render() {
    let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {alert}
        {this.state.duplicated ? (
          <Redirect
            to={`/invoices/${
              this.state.invoiceDuplicated && this.state.invoiceDuplicated
            }/edit`}
          />
        ) : null}
        {this.state.dashboard && (
          <>
            <InvoiceList
              billingCentral
              invoices={this.state.invoices}
              getInvoicePdf={this.getInvoicePdf}
              invoice_pdf={this.state.invoice_pdf}
              editStatus={this.editStatus}
              sendNotification={this.sendNotification}
              handleDuplicateInvoice={this.handleDuplicateInvoice}
              handleDeleteInvoice={this.handleDeleteInvoice}
              handleAmendInvoice={this.handleAmendInvoice}
              handleSelectInvoicesToDownload={this.getInvoicesPdf}
              handleSelectInvoicesToSend={this.handleSendNotificationInvoices}
              columns={this.context.translation.dataTables.invoices.columns}
              title={this.context.translation.dataTables.invoices.title}
              invoicesToPDF={this.state.invoicesToPDF}
              dashboard={this.state.dashboard}
              getFilteredInvoices={this.getFilteredInvoices}
              year={this.state.year}
              handleChangeYear={this.handleChangeYear}
              centralUser={true}
              handleSelectYears={this.handleSelectYears}
              compareInvoices={this.state.compareInvoices}
              totals={this.state.totals}
              compareYears={this.state.compareYears}
            />
          </>
        )}
      </Grid>
    );
  }
}

export default InvoiceListComponent;
