import React, { Component } from "react";
import SettingsServices from "services/central-billing/settings.services";
import UserContext from "providers/UserProvider";

import { BillingSettings, Grid } from "react-library-sm";

class BillingSettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.settingsService = new SettingsServices();
    this.state = {
      series: [],
      payment_methods: [],
      billing_info: {},
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    this.settingsService
      .getSettings()
      .then((response) => {
        // console.log("getSettings response", response);
        this.setState({
          series: response.data.billing.series,
          payment_methods: response.data.billing.payment_methods,
          billing_info: response.data.billingInfo,
        });
      })
      .catch((err) => console.log(err));
  }

  /* Payment Methods */
  createPaymentMethod = (values) => {
    this.settingsService
      .postPaymentMethod(values)
      .then(
        (response) =>
          response.status === 200 &&
          this.setState({ payment_methods: response.data })
      )
      .catch((err) => console.log(err));
  };

  editPaymentMethod = (values) => {
    this.settingsService.putPaymentMethod(values).then((response) => {
      let payment_methods = [...response.data];
      this.setState({ payment_methods });
    });
  };

  deletePaymentMethod = (selected) => {
    // console.log("deletePaymentMethod selected", selected);
    this.settingsService.deletePaymentMethod(selected._id).then((response) => {
      // console.log("response delete payment method", response.data);
      if (response.status === 200) {
        let payment_methods = [...this.state.payment_methods];
        payment_methods.some((method, index) => {
          if (method._id === selected._id) {
            payment_methods.splice(index, 1);
            return true;
          } else return false;
        });
        this.setState({ payment_methods });
      }
    });
  };

  createSerie = (values) => {
    this.settingsService
      .postSerie(values)
      .then(
        (response) =>
          response.status === 200 && this.setState({ series: response.data })
      )
      .catch((err) => console.log(err));
  };

  editSerie = (values) => {
    this.settingsService
      .editSerie(values)
      .then(
        (response) =>
          response.status === 200 && this.setState({ series: response.data })
      )
      .catch((err) => console.log(err));
  };
  deleteSerie = (selected) => {
    this.settingsService.deleteSerie(selected._id).then((response) => {
      if (response.status === 200) {
        let series = [...this.state.series];
        series.some((method, index) => {
          if (method._id === selected._id) {
            series.splice(index, 1);
            return true;
          } else return false;
        });
        this.setState({ series });
      }
    });
  };
  editInfo = (values) => {
    this.settingsService
      .editInfo(values)
      .then(
        (response) =>
          response.status === 200 &&
          this.setState({ billing_info: response.data })
      )
      .catch((err) => console.log(err));
  };
  render() {
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        <BillingSettings
          payment_methods={
            this.state.payment_methods ? this.state.payment_methods : null
          }
          createPaymentMethod={this.createPaymentMethod}
          editPaymentMethod={this.editPaymentMethod}
          deletePaymentMethod={this.deletePaymentMethod}
          billing_info={this.state.billing_info}
          editInfo={this.editInfo}
          series={this.state.series ? this.state.series : null}
          createSerie={this.createSerie}
          editSerie={this.editSerie}
          deleteSerie={this.deleteSerie}
          columns={{
            series: this.context.translation.dataTables.series.columns,
            payment_methods:
              this.context.translation.dataTables.payment_methods.columns,
          }}
          titles={{
            series: this.context.translation.dataTables.series.title,
            payment_methods:
              this.context.translation.dataTables.payment_methods.title,
          }}
        />
      </Grid>
    );
  }
}

export default BillingSettingsComponent;
