import React, { Component } from "react";
import {
  FranchiseProjectSingle as ProjectsSingle,
  Paper
} from "react-library-sm";

import UserContext from "providers/UserProvider";

import ProjectsServices from "services/franchise-projects/projects.services";

import { Redirect } from "react-router-dom";

class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: null,
      deletedProject: false,
      tasksCollapse: false
    };
    this.projectService = new ProjectsServices();
  }

  static contextType = UserContext;

  componentDidMount() {
    this.projectService
      .getProject(
        this.props.match.params.id,
        this.props.match.params.franchiseid
      )
      .then(response => {
        console.log("getProject response", response);
        if (response.data.length > 0) {
          const project = {
            ...response.data[0],
            template: {
              phases: response.data[0].template,
              name: response.data[0].infoProject.name,
              infoProject: response.data[0].infoProject,
              contact: response.data[0].client && response.data[0].client[0]
            }
          };
          console.log("getProject response", project);
          this.setState({ project });
        }
      })
      .catch(err => console.log(err));
  }

  editStatusTask = (status, key, tasksCollapse) => {
    const taskToEdit = {
      ...this.state.project.template.phases.find(item => item.key === key)
    };
    taskToEdit.status = status;

    this.projectService
      .updateTask(
        this.state.project._id,
        taskToEdit.key,
        taskToEdit,
        this.props.match.params.franchiseid
      )
      .then(response => {
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0]
            }
          },
          tasksCollapse
        });
      });
  };

  editStatusTaskRecurrency = (
    status,
    _idTaskRecurrency,
    key,
    tasksCollapse
  ) => {
    const taskToEdit = {
      ...this.state.project.template.phases.find(item => item.key === key)
    };
    if (taskToEdit.repeat) {
      taskToEdit.repeats = taskToEdit.repeats.map(_taskRecurrency => {
        if (_taskRecurrency.key === _idTaskRecurrency) {
          return { ..._taskRecurrency, status };
        } else {
          return { ..._taskRecurrency };
        }
      });

      this.projectService
        .updateTask(
          this.state.project._id,
          taskToEdit.key,
          taskToEdit,
          this.props.match.params.franchiseid
        )
        .then(response => {
          this.setState({
            project: {
              ...response.data,
              template: {
                phases: response.data.template,
                name: response.data.infoProject.name,
                infoProject: response.data.infoProject,
                contact: response.data.client && response.data.client[0]
              }
            },
            tasksCollapse
          });
        });
    } else {
      console.log("error tarea no recurrente");
    }
  };

  editContentTask = task => {
    this.projectService
      .updateTask(
        this.state.project._id,
        task.key,
        task,
        this.props.match.params.franchiseid
      )
      .then(response => {
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0]
            }
          }
        });
      });
  };

  createInsideTask = (newTask, nameParent, parent, newKeyParent) => {
    const body = {
      newTask,
      keyPadre: parent,
      namePadre: nameParent,
      keyNew: newKeyParent
    };
    this.projectService
      .addInsideTask(
        body,
        this.props.match.params.id,
        this.props.match.params.franchiseid
      )
      .then(response => {
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0]
            }
          }
        });
      });
  };

  createAfterTask = (newTask, parent) => {
    this.projectService
      .addTask(
        newTask,
        this.props.match.params.id,
        this.props.match.params.franchiseid
      )
      .then(response => {
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0]
            }
          }
        });
      });
  };

  deleteProject = () => {
    this.projectService
      .deleteProject(
        this.props.match.params.id,
        this.props.match.params.franchiseid
      )
      .then(response => {
        this.setState({ deletedProject: true });
      });
  };
  deleteTask = task => {
    this.projectService
      .deleteTask(
        this.state.project._id,
        task.key,
        this.props.match.params.franchiseid
      )
      .then(response => {
        this.setState({
          project: {
            ...response.data,
            template: {
              phases: response.data.template,
              name: response.data.infoProject.name,
              infoProject: response.data.infoProject,
              contact: response.data.client && response.data.client[0]
            }
          }
        });
      });
  };

  render() {
    let content = this.state.deletedProject ? (
      <Redirect to="/projects" />
    ) : (
      <Paper elevation={0} style={{ background: "transparent" }}>
        <ProjectsSingle
          project={this.state.project ? this.state.project.template : null}
          createTask={this.createTask}
          editStatusTask={this.editStatusTask}
          editStatusTaskRecurrency={this.editStatusTaskRecurrency}
          editContentTask={this.editContentTask}
          createAfterTask={this.createAfterTask}
          createInsideTask={this.createInsideTask}
          deleteProject={this.deleteProject}
          editTask={this.editContentTask}
          deleteTask={this.deleteTask}
          tasksCollapse={this.state.tasksCollapse}
        />
      </Paper>
    );
    return content;
  }
}

export default ProjectContainer;
