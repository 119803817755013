import React, { Component } from "react";
import FranchiseServices from "services/franchises.services.js";

import ProjectsServices from "services/franchise-projects/projects.services";
import InvoiceServices from "services/central-billing/invoices.services";
import UserContext from "providers/UserProvider";
import { InvoiceSingle, Grid, Skeleton } from "react-library-sm";
import { Redirect } from "react-router-dom";

class InvoiceSingleComponent extends Component {
  constructor(props) {
    super(props);
    this.franchiseService = new FranchiseServices();
    this.invoiceService = new InvoiceServices();
    this.projectService = new ProjectsServices();
    this.state = {
      info: null,
      created: false,
      invoice: null,
      invoice_pdf: null,
      deleted: false,
    };
  }
  static contextType = UserContext;

  componentDidMount() {
    // console.log("this.props.variant", this.props.variant);
    if (this.props.variant !== "add") {
      this.invoiceService.getInvoice(this.props.id).then((response) => {
        // console.log("getInvoice respoonse", response);
        // console.log("getInvoice total", response.data.total);
        this.props.variant === "edit"
          ? this.setState({
              invoice: {
                ...response.data,
                franchise: response.data.franchise._id,
              },
              franchise: response.data.franchise._id,
            })
          : this.setState({ invoice: response.data });
      });
    }

    this.invoiceService.getInvoiceInfo().then((response) => {
      // console.log("getInvoiceInfo respoonse", response);
      const info = {
        clients: response.data.clients.map((client) => {
          return { ...client, value: client._id, label: client.name };
        }),
        series: response.data.series.map((serie) => {
          return { ...serie, value: serie._id, label: serie.name };
        }),
        payment_methods: response.data.payment_methods.map((method) => {
          return { ...method, value: method._id, label: method.name };
        }),
      };
      // console.log("info", info);
      this.setState({ info });
    });
  }

  createInvoice = (values) => {
    // console.log("createInvoice values", JSON.stringify(values));

    this.invoiceService
      .postInvoice(values)
      .then((response) => {
        // console.log("response postInvoice", response);
        if (response.status === 200) {
          this.setState({ created: true });
          // console.log("factura creada");
        }
      })
      .catch((err) => console.log(err));
  };

  editInvoice = (values) => {
    // console.log("editInvoice values", values);

    this.invoiceService
      .putInvoice(values)
      .then((response) => {
        // console.log("response postInvoice", response);
        if (response.status === 200) {
          this.setState({ edited: true });
          // console.log("factura creada");
        }
      })
      .catch((err) => console.log(err));
  };

  handleCancel = (values) => this.setState({ cancel: true });

  render() {
    return this.state.created || this.state.edited || this.state.cancel ? (
      <Redirect to={`/invoices`} />
    ) : (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {this.props.variant === "edit" &&
        this.state.invoice &&
        this.state.info ? (
          <InvoiceSingle
            variant={this.props.variant}
            info={this.state.info}
            createInvoice={this.createInvoice}
            editInvoice={this.editInvoice}
            editStatus={this.editStatus}
            invoice={this.state.invoice}
            handleCancelEdit={this.handleCancel}
            drawerOpen={this.props.drawerOpen}
          />
        ) : this.props.variant === "add" ? (
          this.state.info && (
            <InvoiceSingle
              variant={this.props.variant}
              info={this.state.info}
              createInvoice={this.createInvoice}
              editInvoice={this.editInvoice}
              editStatus={this.editStatus}
              invoice={this.state.invoice}
            />
          )
        ) : (
          <Skeleton></Skeleton>
        )}
      </Grid>
    );
  }
}

export default InvoiceSingleComponent;
