import Axios from "core/axios";

class SubscriptionServices {
  constructor() {
    this.api_url = process.env.REACT_APP_API_CENTRAL_BILLING;
    this.service = new Axios(this.api_url);
  }

  getSubscriptions = (year) =>
    this.service.get(`/subscriptions?centralId=5df776376aa15623df72a6b7${year ? `&year=${year}` : "" }`);
  getSubscriptionInfo = () => this.service.get("/subscriptions/info");
  getSubscription = id =>
    this.service.get(`/subscriptions/${id}?centralId=5df776376aa15623df72a6b7`);
  postSubscription = data =>
    this.service.post(
      "/subscriptions?centralId=5df776376aa15623df72a6b7",
      data
    );
  putSubscription = data =>
    this.service.put(`/subscriptions/${data._id}`, data);
  deleteSubscription = id => this.service.delete(`/subscriptions/${id}`);
  issueNewInvoice = data =>
    this.service.post(
      "/subscriptions/invoice?centralId=5df776376aa15623df72a6b7",
      data
    );
}

export default SubscriptionServices;
