import React, { Component } from "react";
import UserContext from "providers/UserProvider";
import { Grid, AcademyCourseView } from "react-library-sm";

class AcademyCoursesPreviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertAPI: false,
      alert: null,
    };
  }

  handleOnCloseAlert = () => {
    this.setState({ alert: {}, alertAPI: false });
  };
  static contextType = UserContext;

  componentDidMount() {
    // load courses
    // console.log(
    //   "props",
    //   localStorage.getItem("central-sportmadness-manager-preview-code")
    // );
    window.addEventListener("beforeunload", (event) => {
      // Cancel the event as stated by the standard.
      localStorage.removeItem("central-sportmadness-manager-preview-code");
      //   event.preventDefault();
      // Chrome requires returnValue to be set.
      //   event.returnValue = "";
    });
  }

  render() {
    // let alert = this.state.alertAPI ? <Alert {...this.state.alert} /> : null;
    return (
      <Grid
        container
        component="main"
        style={{
          padding: "4px 25px",
        }}
      >
        {alert}
        <AcademyCourseView
          data={localStorage.getItem(
            "central-sportmadness-manager-preview-code"
          )}
        />
      </Grid>
    );
  }
}

export default AcademyCoursesPreviewComponent;
